<template>
  <div>
    <el-dialog
      title="二维码详情"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
      width="1200px">
    <div class="dio-code">
      <div class="dio-code-info">
        <div class="dio-code-box">
          <div>
            <span v-if="qrcode_info.sn">已绑定设备</span>
            <span v-else>未绑定设备</span>
            <component :is="'connect'"></component>
          </div>
          <img :src="getQrcodeSrc(qrcode_info.qrcode_url)" alt="" />
          <span>{{qrcode_info.sn}}</span>
        </div>
        <div class="dio-code-text-info">
          <p>商户基础信息</p>
          <div class="user-info" v-if="merchant_detail.base_info">
            <div>
              <div><span>门店名称</span><span>{{merchant_detail.base_info.mch_name}}</span></div>
              <div>
                <span>门店地址</span
                ><span>{{merchant_detail.base_info.address}}</span>
              </div>
              <div><span>门店电话</span><span>{{merchant_detail.base_info.contact_phone}}</span></div>
              <!-- <div><span>营业时间</span><span>09:00-22:00</span></div> -->
            </div>
            <div>
              <div><span>商户号</span><span>{{merchant_detail.base_info.mchid || '-'}}</span></div>
              <div><span>联系人</span><span>{{merchant_detail.base_info.contact_name}}</span></div>
              <div><span>联系电话</span><span>{{merchant_detail.base_info.contact_phone}}</span></div>
              <div><span>联系邮箱</span><span>{{merchant_detail.base_info.contact_email_one}}</span></div>
            </div>
            <div>
              <div><span>所属代理商</span><span>{{merchant_detail.base_info.agent_name || '-'}}</span></div>
              <div><span>提交时间</span><span>{{merchant_detail.base_info.create_time || '-'}}</span></div>
              <div>
                <span>手续费比例</span>
                <span v-if="merchant_detail.base_info.pay_rate&&merchant_detail.base_info.pay_rate.length > 0">
                  {{+merchant_detail.base_info.pay_rate[0].pay_rate}}%
                </span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
          <p>图片信息</p>
          <div class="user-info" v-if="merchant_detail.image_info">
            <div>
              <div v-if="merchant_detail.image_info.shop_pic_url">
                <span>门店图片</span>
                <div><img v-for="(item, index) in merchant_detail.image_info.shop_pic_url.split(';')" :key="index" :src="item" alt="" /></div>
              </div>
            </div>
            <div>
              <div v-if="merchant_detail.image_info.legal_entity_id_pic_name_url">
                <span>身份证</span>
                <div><img v-for="(item, index) in merchant_detail.image_info.legal_entity_id_pic_name_url.split(';')" :key="index" :src="item" alt="" /></div>
              </div>
            </div>
            <div>
              <div v-if="merchant_detail.image_info.business_license_pic_name_url">
                <span>营业执照</span>
                <div><img v-for="(item, index) in merchant_detail.image_info.business_license_pic_name_url.split(';')" :key="index" :src="item" alt="" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>已绑定设备</p>
      <div class="bind-facility">
        <div class="bind-facility-item" v-for="(item, index) in device_box" :key="index" :class="index >= 0 ? 'unbind-facility-item' : ''">
          <div><span>设备编号</span><span>{{item.sn}}</span></div>
          <div><span>绑定日期</span><span>{{item.bind_time}}</span></div>
          <component :is="item.is_online == 1 ? 'connect' : 'unconnect'" svgSize="14" class="svg-code"></component>
        </div>
      </div>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  $sg_qrcode_detail,
} from '@/api/incomingPay';
import { getQrcodeSrc } from '@/global/globalMethods';

import connect from '@/components/cloud/connect.vue';
import unconnect from '@/components/cloud/unconnect.vue';

export default {
  components: {
    unconnect,
    connect,
  },
  data() {
    return {
      getQrcodeSrc,
      dialogVisible: false,
      merchant_detail: {},
      qrcode_info: {},
      device_box: {},
    };
  },
  methods: {
    // 打开弹窗
    open(id) {
      this.dialogVisible = true;
      this.merchant_detail = {};
      this.qrcode_info = {};
      this.device_box = {};
      $sg_qrcode_detail(id).then((res) => {
        this.merchant_detail = res.merchant_detail || {};
        this.qrcode_info = res.qrcode_info || {};
        this.device_box = res.device_box || {};
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dio-code {
  font-size: 14px;
  color: #101010;
  p {
    margin-bottom: 12px;
    font-weight: bold;
  }
  .dio-code-info {
    display: flex;
    .dio-code-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 206px;
      height: 243px;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #ef3f46;
      box-shadow: 0px 1px 5px 0px rgba(239, 63, 70, 100);
      padding: 4px 5px 18px;
      > span {
        font-size: 14px;
        color: #101010;
      }
      > img {
        width: 120px;
        height: 120px;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        > span {
          font-size: 12px;
          color: #969ca7;
        }
        > i {
          color: #24ff00;
          font-size: 14px;
        }
      }
    }
    .dio-code-text-info {
      margin-left: 12px;
      .user-info {
        display: flex;
        margin-bottom: 18px;
        > div {
          margin-right: 15px;
          > div {
            margin-bottom: 10px;
            > span {
              &:nth-child(1) {
                display: inline-block;
                width: 80px;
                text-align: right;
                margin-right: 15px;
              }
            }
            > div {
              display: inline-block;
              img {
                width: 54px;
                height: 54px;
                margin-right: 8px;
              }
            }
          }
          &:nth-child(1) {
            > div {
              > span {
                display: inline !important;
              }
            }
          }
        }
      }
    }
  }
  .bind-facility {
    display: flex;
    flex-wrap: wrap;
    width: 460px;
    .bind-facility-item {
      border: 1px solid #20d750;
      border-radius: 4px;
      padding: 3px 4px;
      padding-right: 30px;
      position: relative;
      margin-right: 16px;
      margin-bottom: 11px;
      > .svg-code {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      > div {
        > span {
          &:nth-child(1) {
            margin-right: 15px;
          }
        }
        &:nth-child(1) {
          margin-bottom: 5px;
        }
      }
    }
    .unbind-facility-item {
      border-color: #969ca7;
      > i {
        color: #969ca7;
      }
    }
  }
}
</style>
