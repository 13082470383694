<template>
  <!-- 添加设备使用情况 -->
  <el-card class="add-equipment-usage-page">
    <el-page-header @back="$router.go(-1)"></el-page-header>
    <div class="content">
      <div class="fb content-inner">
        <div class="acount-info">
          <p class="title">代理商账户信息</p>
          <div class="fb acount-info-center">
            <div class="moeny-info">
              <p class="label">当前余额（元）</p>
              <p class="moeny">{{account.usable}}</p>
            </div>
          </div>
          <p class="fb">
            <span>全部已提：{{account.withdraw_money}}</span>
            <span>冻结金额：{{account.frozen}}</span>
            <span v-if="account.state == 0">账户状态：无效</span>
            <span v-else-if="account.state == 1">账户状态：正常</span>
            <span v-else>账户状态：-</span>
          </p>
        </div>
        <div class="agent-info">
          <p class="title">代理商基本信息</p>
          <div class="agent-info-box">
            <div>
              <p><span>代理商名称: </span>{{base.agent_name || '-'}}</p>
              <p><span>联系人: </span>{{base.real_name || '-'}}</p>
              <p><span>联系电话: </span>{{base.phone || '-'}}</p>
              <p><span>所在地: </span>{{base.address || '-'}}</p>
            </div>
            <div>
              <p class="flex">
                <span>归属分公司: </span>
                <span>-</span>
              </p>
              <p class="flex">
                <span>支付费率: </span>
                <el-input v-model="form.payment_rate" size="small" placeholder="请输入"></el-input>
                <span>%</span>
              </p>
              <p class="flex">
                <span>提现手续费: </span>
                <el-input v-model="form.withdraw_service_charge" size="small" placeholder="请输入"></el-input>
                <span>元/笔</span>
              </p>
              <p class="flex">
                <span>起提金额: </span>
                <el-input v-model="form.min_withdraw_limit" size="small" placeholder="请输入"></el-input>
                <span>元</span>
              </p>
            </div>
          </div>
        </div>
        <div class="business-info">
          <p class="title">代理商业务信息</p>
          <div class="fb business-info-box">
            <div>
              <p class="titel">收款码商户(户)</p>
              <p class="value">{{merchant.merchant_count || '-'}}</p>
            </div>
            <div>
              <p class="titel">云喇叭开通(户)</p>
              <p class="value">{{merchant.open_device_box_merchant_count || '-'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-table">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="update_agent">确定</el-button>
    </div>
  </el-card>
</template>

<script>
import {
  $sg_agentsDetail,
  $sp_update_agent,
} from '@/api/incomingPay';

export default {
  name: 'AgentDetail',
  components: {
  },
  data() {
    return {
      form: {
        payment_rate: '',
        withdraw_service_charge: '',
        min_withdraw_limit: '',
      },
      account: {},
      base: {},
      merchant: {},
    };
  },
  created() {
    this.agent_sid = this.$route.query.agent_sid;
    this.agentsDetail();
  },
  methods: {
    // 查询代理商详情
    agentsDetail() {
      $sg_agentsDetail(this.agent_sid).then((res) => {
        this.account = res.account || {};
        this.base = res.base || {};
        this.merchant = res.merchant || {};
        this.form.payment_rate = res.base.payment_rate;
        this.form.withdraw_service_charge = res.base.withdraw_service_charge;
        this.form.min_withdraw_limit = res.base.min_withdraw_limit;
      });
    },
    // 编辑
    async update_agent() {
      await this.$validator({
        ...this.form,
      }, [
        'payment_rate/require/支付费率',
        'withdraw_service_charge/require/提现手续费',
        'min_withdraw_limit/require/起提金额',
      ]);
      const params = {
        agent_id: this.agent_sid,
        ...this.form,
      };
      $sp_update_agent(params).then(() => {
        this.agentsDetail();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.fb {
  display: flex;
  justify-content: space-between;
}
.add-equipment-usage-page {
  width: 100%;
  height: 100%;
  border: none;
  ::v-deep .el-card__body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;
  }
  .content {
    flex: 1;
    .content-inner {
      > div {
        width: calc((100% / 3) - 30px);
        .title {
          margin: 20px 0;
          font-size: 14px;
          color: #101010;
        }
      }
    }
    .acount-info {
      .acount-info-center {
        margin-bottom: 30px;
        .moeny-info {
          width: 50%;
          color: #484c5c;
          p {
            text-align: center;
          }
          .label {
            font-size: 18px;
          }
          .moeny {
            font-weight: 700;
            font-size: 36px;
            margin-top: 10px;
          }
        }
      }
    }
    .agent-info {
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      box-sizing: border-box;
      padding: 0 10px;
      .agent-info-box {
        display: flex;
        font-size: 14px;
        color: #101010;
        > div {
          width: 50%;
          > p {
            >span{
              display: inline-block;
              width:80px;
            }
            margin-bottom: 10px;
          }
        }
        .el-input{
          width: 100px;
        }
      }
    }
    .business-info-box {
      display: flex;
      > div {
        width: 50%;
        .titel {
          font-size: 18px;
          color: #484c5c;
          margin-bottom: 20px;
        }
        .value {
          font-weight: 700;
          font-size: 24px;
          color: #484c5c;
        }
      }
    }
  }
  .footer-table {
    border-top: 1px solid #bbbbbb;
    box-sizing: border-box;
    padding-top: 10px;
    height: 60px;
  }
}
</style>
