<template>
  <div class="merchantDetail-box">
    <el-page-header @back="$router.back()" content="商户详情"> </el-page-header>
    <div class="descriptions-box">
      <h4>今日交易数据</h4>
      <div class="sorr-box">
        <div v-if="todayData && todayData.length > 0" class="arrow-icon-box" @click="prePageTodayData"><i class="el-icon-arrow-left"></i></div>
        <div class="item-box">
          <div class="item pointer" v-for="(item, index) in todayData" :key="index">
            <div
              class="lefe-info-box"
              @click="selectItem(item, index)"
              :class="index === selectIndex ? 'select-left-box' : ''"
            >
              <div class="top">
                <span v-if="item.is_bind_device_box == 1">已绑定设备</span>
                <span class="no-bind" v-else>未绑定设备</span>
                <component
                  :is="item.is_online == 1 ? 'connect' : 'unconnect'"
                ></component>
              </div>
              <div class="mid-text" v-if="item.is_bind_device_box == 1">
                <span>{{item.sn}}</span>
                <span>收款金额(元)</span>
                <span>{{item.qrcode_order_sum}}</span>
                <span>收款笔数(笔)</span>
                <span>{{item.qrcode_order_count}}</span>
              </div>
              <div class="mid-img" v-else>
                <img :src="getQrcodeSrc(item.qrcode_url)" alt="" />
                <span>{{item.sn}}</span>
              </div>
              <div class="bot">
                <div @click.stop="openDio(item)">查看收款码</div>
                <div @click.stop="changeCodeState(item.id, 2)" v-if="item.state == 1">收款码失效</div>
                <div class="effect" @click.stop="changeCodeState(item.id, 1)" v-if="item.state == 2">收款码生效</div>
              </div>
              <div class=""></div>
            </div>
            <div class="right-info-box" :class="selectIndex === index ? 'select-right-box' : ''">
              <div class="info-text">
                <i class="el-icon-error pointer" @click="selectIndex = ''"></i>
                <div><span>门店名称</span><span v-if="qrcodeDetail.merchant_detail && qrcodeDetail.merchant_detail.base_info">{{qrcodeDetail.merchant_detail.base_info.mch_name}}</span></div>
                <div>
                  <span>门店地址</span
                  ><span v-if="qrcodeDetail.merchant_detail && qrcodeDetail.merchant_detail.base_info">{{qrcodeDetail.merchant_detail.base_info.address}}</span>
                </div>
                <div><span>门店电话</span><span v-if="qrcodeDetail.merchant_detail && qrcodeDetail.merchant_detail.base_info">{{qrcodeDetail.merchant_detail.base_info.contact_phone}}</span></div>
                <div class="info-box" v-for="(item, index) in qrcodeDetail.device_box" :key="index">
                  <component
                    class="info-box-icon"
                    :is="item.is_online == 1 ? 'connect' : 'unconnect'"
                  ></component>
                  <div><span>设备编号</span><span>{{item.sn}}</span></div>
                  <div><span>绑定日期</span><span>{{item.bind_time}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="todayData && todayData.length > 0" class="arrow-icon-box" @click="nextPageTodayData"><i class="el-icon-arrow-right"></i></div>
      </div>
      <h4>商户基础信息</h4>
      <div class="basic-information-box">
        <div class="basic-item">
          <div>
            <div>门店名称</div>
            <span>{{base_info.mch_name}}</span>
          </div>
          <div>
            <div>门店地址</div>
            <span>{{base_info.address}}</span>
          </div>
          <div>
            <div>门店电话</div>
            <span>{{base_info.contact_phone}}</span>
          </div>
          <!-- <div>
            <div>营业时间</div>
            <span>09:00-22:00</span>
          </div> -->
        </div>
        <div class="basic-item">
          <div>
            <div>商户号</div>
            <span>{{base_info.mchid || '-'}}</span>
          </div>
          <div>
            <div>联系人</div>
            <span>{{base_info.contact_name}}</span>
          </div>
          <div>
            <div>联系电话</div>
            <span>{{base_info.contact_phone}}</span>
          </div>
          <div>
            <div>联系邮箱</div>
            <span>{{base_info.contact_email_one}}</span>
          </div>
        </div>
        <div class="basic-item">
          <div>
            <div>所属代理商</div>
            <span>{{base_info.agent_name}}</span>
          </div>
          <div>
            <div>提交时间</div>
            <span>{{base_info.create_time || '-'}}</span>
          </div>
          <div>
            <div>手续费比例</div>
            <span v-if="base_info.pay_rate && base_info.pay_rate.length > 0">{{+base_info.pay_rate[0].pay_rate}}%</span>
          </div>
        </div>
      </div>
      <h4>图片信息</h4>
      <div class="image-info-box">
        <div class="image-info-item">
          <span>门店图片</span>
          <span v-if="image_info.shop_pic_url">
            <img v-for="(item, index) in image_info.shop_pic_url.split(';')" :key="index" :src="item" alt="" />
          </span>
          <span>身份证</span>
          <span v-if="image_info.legal_entity_id_pic_name_url">
            <img v-for="(item, index) in image_info.legal_entity_id_pic_name_url.split(';')" :key="index" :src="item" alt="" />
          </span>
          <span>营业执照</span>
          <span v-if="image_info.business_license_pic_name_url">
            <img v-for="(item, index) in image_info.business_license_pic_name_url.split(';')" :key="index" :src="item" alt="" />
          </span>
        </div>
      </div>
      <h4>交易数据</h4>
      <el-table :data="tableData" border>
        <!-- <el-table-column type="expand">
          <template slot-scope="props">
            <div class="table-box">
              <el-table :data="props.row.children" border :show-header="false">
                <el-table-column label="交易时间" prop="" align="center"></el-table-column>
                <el-table-column label="商户名称" prop="" align="center"></el-table-column>
                <el-table-column label="门店名称" prop="" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="联系电话" prop="phone" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.phone}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="交易金额（元）" prop="trade_money" align="center"></el-table-column>
                <el-table-column label="交易笔数" prop="trade_count" align="center"></el-table-column>
                <el-table-column label="实际到账金额（元）" prop="profit" align="center"></el-table-column>
                <el-table-column label="产生手续费（元）" prop="" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.trade_money - scope.row.profit}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" plain size="mini" @click="toEdit(scope.row)">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="交易时间" prop="month" align="center"></el-table-column>
        <el-table-column label="商户名称" prop="name" align="center"></el-table-column>
        <el-table-column label="门店名称" prop="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.children && scope.row.children.length > 0">{{scope.row.children[0].name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系电话" prop="phone" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.children && scope.row.children.length > 0">{{scope.row.phone}}</span>
          </template>
        </el-table-column>
        <el-table-column label="交易金额（元）" prop="trade_money" align="center"></el-table-column>
        <el-table-column label="交易笔数" prop="trade_count" align="center"></el-table-column>
        <el-table-column label="实际到账金额（元）" prop="profit" align="center"></el-table-column>
        <el-table-column label="产生手续费（元）" prop="" align="center">
          <template slot-scope="scope">
            <span>{{(scope.row.trade_money - scope.row.profit).toFixed(2) * 1}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" plain size="mini" v-if="scope.row.children && scope.row.children.length > 0" @click="toEdit(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination class="mt10" background :page-count="totalPage"
                     :current-page.sync="currentPage" @current-change="qrcode_orders">
      </el-pagination> -->
    </div>
    <DialogSlot
      :visible="dialogShow"
      title="二维码"
      width="400px"
      @cancel="dialogShow = false"
      :footerShow="false"
      :textBoxShow="false"
    >
      <template #content>
        <div class="dia-content">
          <img :src="getQrcodeSrc(currentQrcode.qrcode_url)" alt="" />
          <span>{{currentQrcode.sn}}</span>
          <span>开通时间：{{currentQrcode.bind_shop_time || '-'}}</span>
        </div>
      </template>
      <template #footer>
        <div class="dia-btn-box">
          <el-button @click="dialogShow = false">关闭</el-button>
          <el-button type="primary" @click="downloadQrcode(currentQrcode.qrcode_url)">下载</el-button>
        </div>
      </template>
    </DialogSlot>
  </div>
</template>

<script>
import DialogSlot from '@/components/common/DialogSlot.vue';
import connect from '@/components/cloud/connect.vue';
import unconnect from '@/components/cloud/unconnect.vue';
import {
  $sg_merchantsDetail,
  $sg_merchant_qrcode_statistics,
  $sg_qrcode_orders,
  $sp_qrcode_change_state,
  $sg_qrcode_detail,
} from '@/api/incomingPay';
import { getQrcodeSrc } from '@/global/globalMethods';

export default {
  components: {
    DialogSlot,
    connect,
    unconnect,
  },
  data() {
    return {
      getQrcodeSrc,
      mchId: '',
      selectIndex: '',
      qrcodeDetail: {},
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      dialogShow: false,
      base_info: {},
      image_info: {},
      todayData: [],
      todayDataPage: 1,
      tableDataTotalPage: 0,
      currentQrcode: {},
    };
  },
  created() {
    this.mchId = this.$route.query.id;
    if (this.mchId) {
      this.merchantsDetail();
      this.merchant_qrcode_statistics();
      this.qrcode_orders();
    }
  },
  methods: {
    // 详情
    merchantsDetail() {
      $sg_merchantsDetail(this.mchId).then((res) => {
        this.base_info = res.base_info || {};
        this.image_info = res.image_info || {};
      });
    },
    // 今日交易数据
    merchant_qrcode_statistics() {
      const params = {
        page: this.todayDataPage,
        pageSize: 10,
        mch_id: this.mchId,
      };
      $sg_merchant_qrcode_statistics(params).then((res) => {
        this.todayData = res.list;
        this.tableDataTotalPage = res.total_page;
      });
    },
    // 上一页交易数据
    prePageTodayData() {
      if (this.todayDataPage <= 1) {
        this.$message.warning('已经是第一页了');
        return;
      }
      this.todayDataPage -= 1;
      this.merchant_qrcode_statistics();
    },
    // 下一页交易数据
    nextPageTodayData() {
      if (this.todayDataPage >= this.tableDataTotalPage) {
        this.$message.warning('已经最后一页了');
        return;
      }
      this.todayDataPage += 1;
      this.merchant_qrcode_statistics();
    },
    // 商户订单数据
    qrcode_orders() {
      const params = {
        mch_id: this.mchId,
      };
      $sg_qrcode_orders(params).then((res) => {
        const tempArr = [];
        Object.keys(res).forEach((val) => {
          tempArr.push(res[val]);
        });
        this.tableData = tempArr;
      });
    },
    selectItem(item, index) {
      this.selectIndex = index;
      // 请求二维码详情
      $sg_qrcode_detail(item.id).then((res) => {
        this.qrcodeDetail = res;
      });
    },
    // 查看收款码
    openDio(item) {
      this.currentQrcode = item;
      this.dialogShow = true;
    },
    // 收款码失效
    changeCodeState(qrcode_id, state) {
      const params = {
        qrcode_id,
        state,
      };
      $sp_qrcode_change_state(params).then(() => {
        this.merchant_qrcode_statistics();
      });
    },
    // 下载二维码
    downloadQrcode(data) {
      if (!data) {
        return;
      }
      const qrcodeUrl = getQrcodeSrc(data);
      this.getUrlBase64(qrcodeUrl).then((base64) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.download = '收款码';
        a.href = base64;
        document.body.appendChild(a);
        a.click();
      });
    },
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // 允许跨域
        img.src = url;
        img.onload = () => {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          const dataURL = canvas.toDataURL('image/png');
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    // 详情
    toEdit(row) {
      this.$router.push({
        name: 'TransactionData',
        query: {
          mch_id: row.mch_id,
          // shop_id: row.shop_id,
          shop_id: row.children[0].shop_id,
          month: row.month,
          shopName: row.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.merchantDetail-box {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .descriptions-box {
    padding: 10px 0;
  }
  .sorr-box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .arrow-icon-box {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #101010;
      color: #fff;
      cursor: pointer;
      user-select: none;
      flex-shrink: 0;
    }
    .item-box {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      overflow-x: scroll;
      white-space: nowrap;
    }
    .item {
      display: flex;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      .lefe-info-box {
        width: 206px;
        height: 243px;
        border: 1px solid #969ca7;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        .top {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 5px;
          font-size: 12px;
          justify-content: space-between;
          > span {
            color: #39afff;
          }
          .no-bind {
            color: #999;
          }
        }
        .mid-text,
        .mid-img {
          display: flex;
          flex: 1;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          padding: 20px 0 0;
          span {
            &:nth-child(1) {
              font-size: 14px;
              color: #101010;
              margin-bottom: 12px;
            }
            &:nth-child(2),
            &:nth-child(4) {
              font-size: 12px;
              color: #969ca7;
              margin-bottom: 5px;
            }
            &:nth-child(3),
            &:nth-child(5) {
              font-size: 20px;
              color: #2e2f31;
            }
            &:nth-child(3) {
              margin-bottom: 20px;
            }
          }
        }
        .mid-img {
          justify-content: center;
          img {
            width: 120px;
            height: 120px;
            margin-bottom: 8px;
          }
          span {
            font-size: 14px !important;
            color: #101010 !important;
          }
        }
        .bot {
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            width: 103px;
            border-radius: 8px;
            background-color: #a4adb3;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            color: #fff;
            height: 30px;
            box-sizing: border-box;
            cursor: pointer;
            user-select: none;
            &:nth-child(2) {
              border: 1px solid #bbbbbb;
              color: #484d4f;
              background-color: #fff;
            }
          }
          .effect {
            background-color: #20d750 !important;
            color: #fff !important;
          }
        }
      }
      .select-left-box {
        border-color: #ef3f46;
        box-shadow: 0px 1px 5px 0px rgba(239, 63, 70, 100);
      }
      .right-info-box {
        height: 243px;
        border-radius: 0 8px 8px 0;
        border-left: none !important;
        margin-left: -8px;
        box-sizing: border-box;
        width: 0;
        padding: 0;
        display: none;
        transition: all 0.5s ease-in;
        position: relative;
        .info-text {
          width: 100%;
          height: 100%;
          white-space: pre-wrap !important;
          i {
            font-size: 20px;
            position: absolute;
            top: 3px;
            right: 3px;
            color: #969ca7;
          }
          .info-box {
            width: 270px;
            height: 53px;
            padding: 3px;
            border: 1px solid #20d750;
            position: relative;
            border-radius: 4px;
            box-sizing: border-box;
            display: inline-block;
            margin-right: 10px;
            &:nth-child(odd) {
              margin-right: 0;
            }
            > .info-box-icon {
              position: absolute;
              top: 3px;
              right: 3px;
              color: #20d750;
            }
          }
          .gray-info-box {
            border-color: #969ca7;
            i {
              color: #969ca7;
            }
          }
          div {
            margin-bottom: 7px;
            > span {
              color: #252835;
              font-size: 14px;
              &:nth-child(1) {
                margin-right: 15px;
              }
            }
          }
        }
      }
      .select-right-box {
        display: block;
        width: 469px;
        padding: 9px 15px 9px 23px;
        border: 1px solid #bbbbbb;
        overflow: auto;
      }
    }
  }
  .basic-information-box {
    display: flex;
    .basic-item {
      margin-right: 30px;
      font-size: 14px;
      color: #252835;
      > div {
        margin-bottom: 10px;
        > div {
          display: inline-block;
          width: 70px;
          text-align: right;
          margin-right: 15px;
        }
      }
    }
  }
  .image-info-box {
    display: flex;
    .image-info-item {
      display: flex;
      span {
        font-size: 14px;
        color: #252835;
        margin-right: 15px;
        img {
          width: 54px;
          height: 54px;
          border-radius: 5px;
          margin-right: 8px;
        }
      }
    }
  }
}
.dia-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 239px;
    height: 239px;
  }
  span {
    font-size: 14px;
    color: #101010;
    &:nth-child(2) {
      margin-top: 21px;
    }
    &:nth-child(3) {
      margin-top: 61px;
    }
  }
}
.dia-btn-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.table-box {
  width: 100%;
  padding-left: 50px;
  box-sizing: border-box;
}
</style>
