<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      :width="svgSize"
      :height="svgSize"
      style="
        border-color: rgba(0, 0, 0, 0);
        border-width: bpx;
        border-style: undefined;
      "
      filter="none"
    >
      <path
        d="M4.687 2.8l25.456 25.456-1.887 1.887-2.685-2.687c-0.859 0.344-1.854 0.544-2.896 0.544-0.003 0-0.006 0-0.009 0h-13.333c-0.002 0-0.003 0-0.005 0-4.418 0-8-3.582-8-8 0-3.468 2.207-6.421 5.293-7.53l0.056-0.018c-0.007-0.137-0.011-0.297-0.011-0.459 0-1.059 0.176-2.076 0.502-3.024l-0.020 0.066-4.348-4.349 1.887-1.885zM9.333 12c0 0.108 0.003 0.217 0.008 0.324l0.093 1.984-1.872 0.659c-2.090 0.755-3.557 2.721-3.557 5.031 0 2.944 2.384 5.331 5.327 5.336h13.334c0.248 0 0.492-0.017 0.731-0.049l-14.024-14.024c-0.027 0.243-0.040 0.489-0.040 0.74zM16 2.667c0 0 0 0 0 0 5.155 0 9.333 4.179 9.333 9.333 0 0.159-0.004 0.317-0.012 0.474l0.001-0.022c3.14 1.13 5.345 4.082 5.345 7.549 0 1.466-0.394 2.84-1.082 4.022l0.020-0.038-1.991-1.991c0.246-0.591 0.389-1.278 0.389-1.998 0-2.945-2.387-5.332-5.332-5.332-0.721 0-1.408 0.143-2.035 0.402l0.035-0.013-1.992-1.993c1.175-0.673 2.535-1.060 3.987-1.060-0-3.682-2.985-6.667-6.667-6.667-1.437 0-2.767 0.454-3.855 1.227l0.021-0.014-1.907-1.907c1.561-1.23 3.557-1.973 5.726-1.973 0.005 0 0.011 0 0.016 0h-0.001z"
        fill="rgba(150, 156, 167, 1)"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    svgSize: {
      default: '18',
    },
  },
};
</script>

<style>
</style>
