<template>
  <!-- 添加设备使用情况 -->
  <el-card class="add-equipment-usage-page">
    <div class="page-form">
      <el-form label-width="100px" :model="formLabelAlign">
        <div class="info-box">
          <p class="title">商户基本情况</p>
          <el-form-item label="商户名称">
            <el-input v-model="formLabelAlign.name" placeholder="请输入商户名称"></el-input>
          </el-form-item>
          <el-form-item label="商户简称">
            <el-input v-model="formLabelAlign.region" placeholder="请输入商户简称"></el-input>
          </el-form-item>
          <el-form-item label="商户经营地址">
            <el-input v-model="formLabelAlign.type" placeholder="请输入商户经营地址"></el-input>
          </el-form-item>
          <el-form-item label="商户联系电话">
            <el-input v-model="formLabelAlign.type" placeholder="请输入商户联系电话"></el-input>
          </el-form-item>
          <el-form-item label="经营范围">
            <div class="range">
              <el-select v-model="searchParams.region" placeholder="请选择经营范围">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="searchParams.region" placeholder="选择详细类目">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="所属代理商">
            <el-select v-model="searchParams.region" placeholder="请选择代理商">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户编码">
            <el-input v-model="formLabelAlign.type"></el-input>
          </el-form-item>
        </div>
        <div class="info-box">
          <p class="title">设备基本情况</p>
          <el-form-item label="设备名称">
            <el-select v-model="searchParams.region" placeholder="选择设备名称">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生产厂家">
            <el-select v-model="searchParams.region" placeholder="请选择生产厂家">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机型">
            <el-select v-model="searchParams.region" placeholder="请选择版本型号">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="批次号">
            <el-select v-model="searchParams.region" placeholder="请选择批次号">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-select v-model="searchParams.region" placeholder="请选择设备编号">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="footer-btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary">添加</el-button>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'AddOrEditEquipentUsage',
  components: {
  },
  data() {
    return {
      searchParams: {},
      formLabelAlign: {},
      options: [{
        value: '选项1',
        label: '黄金糕',
      }, {
        value: '选项2',
        label: '双皮奶',
      }, {
        value: '选项3',
        label: '蚵仔煎',
      }, {
        value: '选项4',
        label: '龙须面',
      }, {
        value: '选项5',
        label: '北京烤鸭',
      }],
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.add-equipment-usage-page {
  width: 100%;
  height: 100%;
  border:none;
  ::v-deep .el-card__body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
  }

  .page-form {
    flex: 1;
    overflow: auto;
    .el-form {
      display: flex;
      .el-form-item {
        margin-bottom: 10px;
      }
      .el-input,
      .el-select {
        width: 400px;
      }
      .info-box {
        width: 50%;
        .title {
          font-weight: bold;
          font-size: 16px;
          color: rgb(16, 16, 16);
          margin-bottom: 20px;
        }
      }
      .range {
        display: flex;
        ::v-deep .el-select {
          width: 200px !important;
        }
      }
    }
  }
  .footer-btn {
    border-top: 1px solid #bbbbbb;
    box-sizing: border-box;
    padding-top: 10px;
    height: 60px;
  }
}
</style>
