<template>
  <!-- 设备使用情况 -->
  <div class="equipment-usage-page">
    <el-form :inline="true" :model="form" class="equipment-usage-form">
      <el-form-item label="代理商编号">
        <el-input v-model="form.agent_sid" placeholder="请输入代理商编号"></el-input>
      </el-form-item>
      <el-form-item label="代理商名称">
        <el-input v-model="form.agent_name" placeholder="请输入代理商名称"></el-input>
      </el-form-item>
      <el-form-item label="代理商电话">
        <el-input v-model="form.phone" placeholder="请输入代理商电话"></el-input>
      </el-form-item>
      <el-form-item label="代理商旗下收款码商户数">
        <div class="merchants-count">
          <el-select v-model="form.merchant_num_query_operate" clearable placeholder="请选择">
            <el-option label="=" value="="></el-option>
            <el-option label=">" value=">"></el-option>
            <el-option label="<" value="<"></el-option>
          </el-select>
          <el-input v-model="form.merchant_num" placeholder="请输入"></el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset">重置</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tabelData" border style="width: 100%" class="equipment-usage-table">
      <el-table-column prop="agent_sid" label="编号" align="center"></el-table-column>
      <el-table-column prop="agent_name" label="代理名称" align="center"></el-table-column>
      <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="address" label="所在地" align="center"></el-table-column>
      <el-table-column prop="mch_count" label="收款码商户" align="center"></el-table-column>
      <el-table-column prop="usable" label="余额" align="center"></el-table-column>
      <el-table-column prop="last_withdraw_time" label="最近一次提现时间" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.last_withdraw_time || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="last_withdraw_money" label="最近一次提现金额" align="center"></el-table-column>
      <el-table-column prop="state" label="当前状态" align="center">
        <template slot-scope="scope">
          <span type="text" v-if="scope.row.state == 1">启用</span>
          <span type="text" v-else-if="scope.row.state == 2">禁用</span>
          <span type="text" v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="agentDetail(scope.row)">详情</el-button>
          <el-button type="text" @click="agentEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="agentsList">
    </el-pagination>
  </div>
</template>

<script>
import {
  $sg_agentsList,
} from '@/api/incomingPay';

export default {
  name: 'AgentList',
  components: {
  },
  data() {
    return {
      form: {
        agent_sid: '',
        agent_name: '',
        phone: '',
        merchant_num: '',
        merchant_num_query_operate: '',
      },
      currentPage: 1,
      totalPage: 0,
      tabelData: [],
    };
  },
  created() {
    this.search();
  },
  methods: {
    // 查询
    search() {
      this.currentPage = 1;
      this.agentsList();
    },
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
      this.search();
    },
    // 请求代理商列表
    agentsList() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        ...this.form,
      };
      $sg_agentsList(params).then((res) => {
        this.tabelData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 详情
    agentEdit(row) {
      this.$router.push({ 
        name: 'AgentEdit',
        query: {
          agent_sid: row.agent_sid,
        },
      });
    },
    // 编辑
    agentDetail(row) {
      this.$router.push({ 
        name: 'AgentDetail',
        query: {
          agent_sid: row.agent_sid,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.equipment-usage-page {
  width: 100%;
  border: none;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .equipment-usage-form {
    .el-form-item {
      margin-bottom: 10px;
    }
    .merchants-count {
      .el-select,
      .el-input {
        width: 160px;
        margin-right: 10px;
      }
    }
  }
  .equipment-usage-table {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
