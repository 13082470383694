<template>
  <!-- 添加设备使用情况 -->
  <el-card class="add-equipment-usage-page">
    <el-page-header  @back="$router.go(-1)"></el-page-header>
    <div class="fb content">
      <div class="acount-info">
        <p class="title">代理商账户信息</p>
        <div class="fb acount-info-center">
          <div class="moeny-info">
            <p class="label">当前余额（元）</p>
            <p class="moeny">{{account.usable}}</p>
          </div>
        </div>
        <p class="fb">
          <span>全部已提：{{account.withdraw_money}}</span>
          <span>冻结金额：{{account.frozen}}</span>
          <span v-if="account.state == 0">账户状态：无效</span>
          <span v-else-if="account.state == 1">账户状态：正常</span>
          <span v-else>账户状态：-</span>
        </p>
      </div>
      <div class="agent-info">
        <p class="title">代理商基本信息</p>
        <div class="agent-info-box">
          <div>
            <p><span>代理商名称: </span>{{base.agent_name || '-'}}</p>
            <p><span>联系人: </span>{{base.real_name || '-'}}</p>
            <p><span>联系电话: </span>{{base.phone || '-'}}</p>
            <p><span>所在地: </span>{{base.address || '-'}}</p>
          </div>
          <div>
            <p><span>归属分公司: </span>{{base.spread_name || '-'}}</p>
            <p><span>支付费率: </span>{{base.payment_rate || '-'}}%</p>
            <p><span>提现手续费: </span>{{base.withdraw_service_charge || '-'}}元/笔</p>
            <p><span>起提金额: </span>{{base.min_withdraw_limit || '-'}}元</p>
          </div>
        </div>
      </div>
      <div class="business-info">
        <p class="title">代理商业务信息</p>
        <div class="fb business-info-box">
          <div>
            <p class="titel">收款码商户(户)</p>
            <p class="value">{{merchant.merchant_count || '-'}}</p>
          </div>
          <div>
            <p class="titel">云喇叭开通(户)</p>
            <p class="value">{{merchant.open_device_box_merchant_count || '-'}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-table">
      <p style="margin:10px 0;">代理商下属商户</p>
      <el-table :data="tabelData" border style="width: 100%">
        <el-table-column prop="id" label="商户号" align="center"></el-table-column>
        <el-table-column prop="mch_name" label="商户名称" align="center"></el-table-column>
        <el-table-column prop="contact_phone" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="operate_address" label="所在地" align="center"></el-table-column>
        <el-table-column prop="" label="业务类型" align="center">
          <template>
            <span>收款码</span>
          </template>
        </el-table-column>
        <el-table-column prop="open_device_box_time" label="收款码开通时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.open_device_box_time || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="手续费比例" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_rate && scope.row.pay_rate.length > 0">{{+scope.row.pay_rate[0].pay_rate || '-'}}%</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="open_qrcode_time" label="设备绑码时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.open_qrcode_time || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="device_box_online_status" label="云喇叭状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.device_box_online_status == 'NONE'">-</span>
            <span v-else-if="scope.row.device_box_online_status == 'ONLINE'">在线</span>
            <span v-else-if="scope.row.device_box_online_status == 'OFFLINE'">离线</span>
            <span v-else-if="scope.row.device_box_online_status == 'UNACTIVE'">未激活</span>
            <span v-else-if="scope.row.device_box_online_status == 'DISABLE'">禁用</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt10" background :page-count="totalPage"
                     :current-page.sync="currentPage" @current-change="agent_merchants">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import {
  $sg_agentsDetail,
  $sg_agent_merchants,
} from '@/api/incomingPay';

export default {
  name: 'AgentDetail',
  data() {
    return {
      agent_sid: '',
      account: {},
      base: {},
      merchant: {},
      currentPage: 1,
      totalPage: 0,
      tabelData: [],
    };
  },
  created() {
    this.agent_sid = this.$route.query.agent_sid;
    this.agentsDetail();
    this.agent_merchants();
  },
  methods: {
    // 查询代理商详情
    agentsDetail() {
      $sg_agentsDetail(this.agent_sid).then((res) => {
        this.account = res.account || {};
        this.base = res.base || {};
        this.merchant = res.merchant || {};
      });
    },
    // 查询代理商旗下商户
    agent_merchants() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        agent_id: this.agent_sid,
      };
      $sg_agent_merchants(params).then((res) => {
        this.tabelData = res.list;
        this.totalPage = res.total_page;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.fb {
  display: flex;
  justify-content: space-between;
}
.add-equipment-usage-page {
  width: 100%;
  border: none;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .content {
    > div {
      width: calc((100% / 3) - 30px);
      .title {
        margin: 20px 0;
        font-size: 14px;
        color: #101010;
      }
    }
    .acount-info {
      .acount-info-center {
        margin-bottom: 30px;
        .moeny-info {
          width: 50%;
          color: #484c5c;
          p {
            text-align: center;
          }
          .label {
            font-size: 18px;
          }
          .moeny {
            font-weight: 700;
            font-size: 36px;
            margin-top: 10px;
          }
        }
      }
    }
    .agent-info {
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      box-sizing: border-box;
      padding: 0 10px;
      .agent-info-box {
        display: flex;
        font-size: 14px;
        color: #101010;
        > div {
          width: 50%;
          > p {
            margin-bottom: 10px;
          }
        }
      }
    }
    .business-info-box {
      display: flex;
      > div {
        width: 50%;
        .titel {
          font-size: 18px;
          color: #484c5c;
          margin-bottom: 20px;
        }
        .value {
          font-weight: 700;
          font-size: 24px;
          color: #484c5c;
        }
      }
    }
  }
}
</style>
