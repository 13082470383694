<template>
  <!-- 添加设备使用情况 -->
  <el-card class="add-equipment-usage-page">
    <div class="page-form">
      <div class="info-box">
        <p class="title">商户基本情况</p>
        <p><span>商户名称：</span><span>{{mch_info.mch_name || '-'}}</span></p>
        <p><span>商户编码：</span><span>{{mch_info.id || '-'}}</span></p>
        <p><span>商户简称：</span><span>{{mch_info.merchant_short_name || '-'}}</span></p>
        <p><span>商户经营地址：</span><span>{{mch_info.operate_address || '-'}}</span></p>
        <p><span>商户联系电话：</span><span>{{mch_info.contact_phone || '-'}}</span></p>
        <p><span>经营范围：</span><span>{{mch_info.business_scope || '-'}}</span></p>
        <p><span>所属代理商：</span><span>{{devicesUsageDetail.agent_name || '-'}}</span></p>
      </div>
      <div class="info-box">
        <p class="title">设备基本情况</p>
        <p><span>设备编号：</span><span>{{devicesUsageDetail.sn || '-'}}</span></p>
        <p><span>机型：</span><span>{{devicesUsageDetail.type_text || '-'}}</span></p>
        <p><span>生产日期：</span><span>{{devicesUsageDetail.create_time || '-'}}</span></p>
        <p><span>设备激活日期：</span><span>{{devicesUsageDetail.bind_shop_time || '-'}}</span></p>
        <p><span>设备状态：</span><span>{{devicesUsageDetail.state_text || '-'}}</span></p>
      </div>
      <div class="info-box">
        <p class="title" style="text-align:center;">绑定收款码</p>
        <div class="qr-code-box">
           <img class="qr-code" :src="getQrcodeSrc(qrcode.qrcode_url)" />
           <p class="No">编号：{{qrcode.sn || '-'}}</p>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
  </el-card>
</template>

<script>

import {
  $sg_devices_usage_detail,
} from '@/api/incomingPay';
import { getQrcodeSrc } from '@/global/globalMethods';

export default {
  name: 'EquipmentUsageDetail',
  components: {
  },
  data() {
    return {
      getQrcodeSrc,
      id: '',
      mch_info: {},
      devicesUsageDetail: {},
      qrcode: {},
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.queryDetail();
    }
  },
  methods: {
    // 详情
    queryDetail() {
      $sg_devices_usage_detail(this.id).then((res) => {
        this.devicesUsageDetail = res || {};
        this.mch_info = res.mch_info || {};
        this.qrcode = res.qrcode || {};
      });
    },
  },
};
</script>

<style scoped lang="scss">
.add-equipment-usage-page {
  width: 100%;
  height: 100%;
  border:none;
  ::v-deep .el-card__body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
  }

  .page-form {
    display: flex;
    flex: 1;
    overflow: auto;
    .info-box {
      width: calc(100% / 3);
      .title {
        font-weight: bold;
        font-size: 16px;
        color: rgb(16, 16, 16);
        margin-bottom: 20px;
      }
      > p:not(:first-child) {
        font-size: 14px;
        margin-bottom: 20px;
        > span:nth-child(1) {
          display: inline-block;
          width: 100px;
          text-align: right;
          color: #101010;
        }
        > span:nth-child(2) {
          color: #969ca7;
        }
      }
      .qr-code-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .qr-code{
          width:160px;
          height: 160px;
        }
        .No{
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
  .footer-btn {
    border-top: 1px solid #bbbbbb;
    box-sizing: border-box;
    padding-top: 10px;
    height: 60px;
  }
}
</style>
