import api from './api';

// 设备管理-设备列表 
export const $sg_devices = (params) => api.get('/p/pay/platform/devices', params);

// 设备管理-设备类型列表
export const $sg_devices_specs = (params) => api.get('/p/pay/platform/devices_specs', params);

// 设备管理-设备详情
export const $sg_devicesDetail = (params) => api.get(`/p/pay/platform/devices/${params}`);

// 设备管理-新增设备
export const $sp_addDevices = (params) => api.post('/p/pay/platform/devices', params);

// 设备管理-批量新增
export const $sp_devices_batch = (params) => api.post('/p/pay/platform/devices_batch', params);

// 设备管理-删除设备
export const $sp_devices_destroy = (params) => api.post('/p/pay/platform/devices_destroy', params);

// 设备管理-批次列表
export const $sg_devices_batch_list = (params) => api.get('/p/pay/platform/devices_batch_list', params);

// 设备管理-全部入库
export const $sp_put_in_storage = (params) => api.post('/p/pay/platform/put_in_storage/all', params);

// 设备管理-单个入库
export const $sp_put_in_storage_single = (params) => api.post('/p/pay/platform/put_in_storage/single', params);

// 设备管理-文件上传入库
export const $sp_put_in_storage_file = (params) => api.post('/p/pay/platform/put_in_storage/file', params);

// 设备使用情况-列表
export const $sg_devices_usage_list = (params) => api.get('/p/pay/platform/devices_usage_list', params);

// 设备使用情况-设备和商户详情
export const $sg_devices_usage_detail = (params) => api.get(`/p/pay/platform/devices_usage/${params}`);

// 设备使用情况-状态变更
export const $sp_device_change_state = (params) => api.post('/p/pay/platform/device_change_state', params);

// 统计-代理商数据-总数
export const $sg_agent_statistics = (params) => api.get('/p/pay/platform/agent_statistics', params);

// 统计-本月商户交易数据图表
export const $sg_month_trade_statistics = (params) => api.get('/p/pay/platform/month_trade_statistics', params);

// 统计-本月交易区间商户数量
export const $sg_month_trade_mch_count = (params) => api.get('/p/pay/platform/month_trade_mch_count', params);

// 代理商管理-代理商列表
export const $sg_agentsList = (params) => api.get('/p/pay/platform/agents', params);

// 代理商管理-代理商详情
export const $sg_agentsDetail = (params) => api.get(`/p/pay/platform/agents/${params}`);

// 代理商管理-旗下商户
export const $sg_agent_merchants = (params) => api.get('/p/pay/platform/agent_merchants', params);

// 代理商管理-代理商编辑
export const $sp_update_agent = (params) => api.post('/p/pay/platform/update_agent', params);

// 代理商提现-列表
export const $sg_agent_withdraw = (params) => api.get('/p/pay/platform/agent_withdraw', params);

// 代理商提现-详情
export const $sg_agent_withdraw_detail = (params) => api.get(`/p/pay/platform/agent_withdraw/${params}`);

// 代理商提现-提现审核
export const $sp_agent_withdraw_audit = (params) => api.post('/p/pay/platform/agent_withdraw_audit', params);

// 商户管理-列表
export const $sg_merchants = (params) => api.get('/p/pay/platform/merchants', params);

// 商户管理-商户详情
export const $sg_merchantsDetail = (params) => api.get(`/p/pay/platform/merchants/${params}`);

// 商户管理-今日交易数据
export const $sg_merchant_qrcode_statistics = (params) => api.get('/p/pay/platform/merchant_qrcode_statistics', params);

// 商户管理-商户订单数据
export const $sg_qrcode_orders = (params) => api.get('/p/pay/platform/merchant/qrcode_orders', params);

// 商户管理-订单列表
export const $sg_qrcode_orders_list = (params) => api.get('/p/pay/platform/qrcode_orders', params);

// 收款码管理-收款码列表
export const $sg_qrcode_list = (params) => api.get('/p/pay/platform/qrcode', params);

// 收款码管理-收款码批次列表
export const $sg_qrcode_batch = (params) => api.get('/p/pay/platform/qrcode_batch', params);

// 收款码管理-一键生成收款码
export const $sp_qrcode_create = (params) => api.post('/p/pay/platform/qrcode_batch', params);

// 收款码管理-二维码详情
export const $sg_qrcode_detail = (params) => api.get(`/p/pay/platform/qrcode/${params}`);

// 收款码管理-收款码状态变更
export const $sp_qrcode_change_state = (params) => api.post('/p/pay/platform/qrcode_change_state', params);




