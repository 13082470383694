<template>
  <div class="transactionData-box">
    <el-page-header @back="$router.back()" content="交易数据"> </el-page-header>
    <div class="storeCheck">
      <el-date-picker v-model="month" value-format="yyyy-MM" type="month" placeholder="选择月" @change="qrcode_orders_list"></el-date-picker>
      <span>{{shopName}}</span>
    </div>
    <!-- <div class="storeCheck">
      <el-form inline>
        <el-form-item label="收款时间">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select v-model="value" placeholder="请选择">
            <el-option label="全部" value="1"> </el-option>
            <el-option label="微信" value="2"> </el-option>
            <el-option label="支付宝" value="3"> </el-option>
            <el-option label="云闪付" value="4"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div> -->
    <el-table :data="tabelData" ref="tabel" border>
      <el-table-column prop="pay_time" label="交易时间"></el-table-column>
      <el-table-column prop="" label="商户名称">
        <template slot-scope="scope">
          <span v-if="scope.row.merchant">{{scope.row.merchant.mch_name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="门店名称">
        <template slot-scope="scope">
          <span v-if="scope.row.shop">{{scope.row.shop.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="联系电话">
        <template slot-scope="scope">
          <span v-if="scope.row.merchant">{{scope.row.merchant.contact_phone}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="收款金额"></el-table-column>
      <el-table-column prop="service_charge" label="手续费"></el-table-column>
      <el-table-column prop="profit" label="实际到账金额（元）"></el-table-column>
      <el-table-column prop="pay_type" label="支付渠道">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_type == 1">微信</span>
          <span v-if="scope.row.pay_type == 2">支付宝</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="qrcode_orders_list">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sg_qrcode_orders_list,
} from '@/api/incomingPay';

export default {
  data() {
    return {
      mch_id: '',
      shop_id: '',
      month: '',
      shopName: '',
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      value: '',
    };
  },
  created() {
    this.mch_id = this.$route.query.mch_id;
    this.shop_id = this.$route.query.shop_id;
    this.month = this.$route.query.month;
    this.shopName = this.$route.query.shopName;
    this.qrcode_orders_list();
  },
  methods: {
    // 请求订单列表
    qrcode_orders_list() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        mch_id: this.mch_id,
        shop_id: this.shop_id,
        start_time: '',
        end_time: '',
      };
      if (this.month) {
        params.start_time = `${this.month}-01`;
        const time = this.month.split('-');
        // 获取当月最后一天日期
        const lastDay = new Date(time[0], time[1], 0).getDate();
        params.end_time = `${this.month}-${lastDay}`;
      }
      $sg_qrcode_orders_list(params).then((res) => {
        this.tabelData = res.list;
        this.totalPage = res.total_page;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.transactionData-box {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .storeCheck {
    margin-top: 10px;
    padding: 10px 0;
    .el-date-editor--month {
      margin-right: 10px;
    }
    &:nth-child(2) {
      border-bottom: 1px solid #bbbbbb;
    }
  }
}
</style>
