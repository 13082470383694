<template>
  <div class="paymentCodesList-content">
    <el-form inline>
      <el-form-item label="生成时间">
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="批次号">
        <el-input placeholder="请输入批次号" v-model="form.batch_no"></el-input>
      </el-form-item>
      <el-form-item label="商户号" v-if="activeName == '1'">
        <el-input placeholder="商户号" v-model="form.mchid"></el-input>
      </el-form-item>
      <el-form-item label="收款码编号" v-if="activeName == '1'">
        <el-input placeholder="输入收款码编号" v-model="form.sn"></el-input>
      </el-form-item>
      <el-form-item label="收款码状态" v-if="activeName == '1'">
        <el-select v-model="form.qrcode_state" placeholder="请选择使用状态" clearable>
          <el-option label="已使用" :value="1"> </el-option>
          <el-option label="未使用" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商户名称/电话" v-if="activeName == '1'">
        <el-input placeholder="商户名称/电话" v-model="form.keywords"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button @click="reset">重置</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeName" type="card" class="code-tabs-box" @tab-click="reset">
      <el-tab-pane label="收款码列表" name="1">
        <el-table ref="firstTable" border :data="tableDataCode">
          <el-table-column label="收款码编号" prop="sn" align="center"></el-table-column>
          <el-table-column label="商户名称" prop="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.merchant">{{scope.row.merchant.mch_name}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" prop="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.merchant">{{scope.row.merchant.contact_phone}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="商户号" prop="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.merchant">{{scope.row.merchant.mchid || '-'}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="绑定设备数量" prop="box_count" align="center"></el-table-column>
          <el-table-column label="批次号" prop="batch_no" align="center"></el-table-column>
          <el-table-column label="开通日期" prop="create_time" align="center"></el-table-column>
          <el-table-column label="状态" prop="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.is_used == 1">已使用</span>
              <span v-if="scope.row.is_used == 0">未使用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="qrcodeDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="totalPageCode"
                       :current-page.sync="currentPageCode" @current-change="qrcode_list">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="批次列表" name="2">
        <el-table ref="secTable" border :data="tableDataBatch">
          <el-table-column prop="create_time" label="生成时间" align="center"></el-table-column>
          <el-table-column prop="total" label="生成数量" align="center"></el-table-column>
          <el-table-column prop="no" label="批次号" align="center"></el-table-column>
          <!-- <el-table-column prop="" label="使用状态" align="center"></el-table-column> -->
          <el-table-column prop="used_qrcode_count" label="已使用数量" align="center"></el-table-column>
          <el-table-column prop="" label="未使用数量" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.total - scope.row.used_qrcode_count}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="" label="创建人" align="center"></el-table-column> -->
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="primary" plain size="mini" @click="toEdit(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="totalPageBatch"
                       :current-page.sync="currentPageBatch" @current-change="qrcode_batch">
        </el-pagination>
      </el-tab-pane>
      <el-button class="tab-btn" @click="openCreateCode">一键生成</el-button>
    </el-tabs>

    <!-- 二维码详情 -->
    <CodeInfo ref="codeInfo" />
    <!-- 一键生成弹窗 -->
    <DialogSlot
      :visible="visible"
      @cancel="visible = false"
      v-bind="dialogData"
    >
      <template #content>
        <el-form inline>
          <el-form-item label="生成数量">
            <el-input v-model.number="codeNum"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="createCode">生成</el-button>
      </template>
    </DialogSlot>
  </div>
</template>

<script>
import DialogSlot from '@/components/common/DialogSlot.vue';
import {
  $sg_qrcode_list,
  $sg_qrcode_batch,
  $sp_qrcode_create,
} from '@/api/incomingPay';
import CodeInfo from './paymentCodesList/components/codeInfo.vue';

export default {
  data() {
    return {
      form: {
        batch_no: '',
        mchid: '',
        sn: '',
        qrcode_state: '',
        keywords: '',
      },
      time: '',
      activeName: '1',
      currentPageCode: 1,
      totalPageCode: 0,
      tableDataCode: [],
      currentPageBatch: 1,
      totalPageBatch: 0,
      tableDataBatch: [],
      visible: false,
      codeNum: '',
      dialogData: {
        title: '一键生成收款码',
        textBoxShow: false,
        width: '400px',
        footerShow: false,
        btnCenter: true,
      },
      dialogType: 'create',
      value: '',
      codeInfoObj: {}, // 二维码详情
    };
  },
  components: {
    DialogSlot,
    CodeInfo,
  },
  created() {
    this.search();
  },
  methods: {
    // 查询
    search() {
      if (this.activeName === '1') {
        this.currentPageCode = 1;
        this.qrcode_list();
      } else if (this.activeName === '2') {
        this.currentPageBatch = 1;
        this.qrcode_batch();
      }
    },
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
      this.time = '';
      this.search();
    },
    // 收款码列表
    qrcode_list() {
      const params = {
        page: this.currentPageCode,
        page_size: 10,
        start_time: '',
        end_time: '',
        ...this.form,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_qrcode_list(params).then((res) => {
        this.tableDataCode = res.list;
        this.totalPageCode = res.total_page;
      });
    },
    // 收款码批次列表
    qrcode_batch() {
      const params = {
        page: this.currentPageBatch,
        page_size: 10,
        start_time: '',
        end_time: '',
        ...this.form,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_qrcode_batch(params).then((res) => {
        this.tableDataBatch = res.list;
        this.totalPageBatch = res.total_page;
      });
    },
    // 打开一键生成收款码弹窗
    openCreateCode() {
      this.dialogType = 'create';
      this.visible = true;
      this.codeNum = '';
    },
    // 一键生成收款码
    createCode() {
      if (!this.codeNum) {
        this.$message.error('生成数量必须大于0');
        return;
      }
      const params = {
        total: this.codeNum,
      };
      $sp_qrcode_create(params).then(() => {
        this.visible = false;
        this.search();
        // 因后端异步生成，等待0.5S之后重新请求一下列表
        setTimeout(() => {
          this.search();
        }, 500);
      });
    },
    // 二维码详情
    qrcodeDetail(row) {
      this.$refs.codeInfo.open(row.id);
    },
    // 批次详情
    toEdit(row) {
      // 批次详情
      this.$router.push({
        name: 'PayCodeTable',
        query: {
          no: row.no,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentCodesList-content {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
