<template>
  <div class="agent-withdrawal-index-page">
    <div class="list-head-search-view">
      <el-form ref="form" :model="form" inline label-width="120px">
        <el-form-item label="代理商编号">
          <el-input type="text" v-model="form.agent_id" placeholder="请输入编号"></el-input>
        </el-form-item>
        <el-form-item label="代理商名称">
          <el-input type="text" v-model="form.agent_name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="代理商电话">
          <el-input type="phone" v-model="form.phone" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="form.apply_state" placeholder="请选择审核状态" clearable>
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="已通过" :value="2"></el-option>
            <el-option label="已驳回" :value="3"></el-option>
            <el-option label="已审核-出款中" :value="4"></el-option>
            <el-option label="已审核-出款失败" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提现时间">
          <el-date-picker v-model="time" type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                          start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="list-head-search-button-view flexbox flex-lr flex-align-center flex-justify-center">
        <el-button plain @click="reset">重置</el-button>
        <el-button type="info" @click="search">查询</el-button>
      </div>
    </div>
    <div class="list-main-view">
      <div class="list-main-table-batch">
        <el-table ref="table" :data="tableData" border style="width: 100%">
          <el-table-column prop="" label="代理编号" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.agent">{{scope.row.agent.agent_sid}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="代理姓名" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.agent">{{scope.row.agent.agent_name}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="联系电话" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.agent">{{scope.row.agent.phone}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="提交申请时间" align="center"></el-table-column>
          <el-table-column prop="apply_money" label="提现金额" align="center"></el-table-column>
          <el-table-column prop="service_charge" label="手续费" align="center"></el-table-column>
          <el-table-column prop="real_money" label="实际到账金额" align="center"></el-table-column>
          <el-table-column prop="receivedType" label="到账方式" align="center">
            <template>
              <span>银行卡</span>
            </template>
          </el-table-column>
          <el-table-column prop="apply_state" label="审核状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.apply_state == 1">待审核</span>
              <span v-else-if="scope.row.apply_state == 2">已通过</span>
              <span v-else-if="scope.row.apply_state == 3">已驳回</span>
              <span v-else-if="scope.row.apply_state == 4">已审核-出款中</span>
              <span v-else-if="scope.row.apply_state == 5">已审核-出款失败</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="detail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="totalPage"
                       :current-page.sync="page" @current-change="agent_withdraw">
        </el-pagination>
      </div>
    </div>
    <DialogSlot :visible.sync="visible" :footerShow="false" :btn-center="true" :lineBottom="false"
                :title="dialogTitle" :center="true" width="1000px">
      <template #content>
        <el-form v-if="examineState === 0" ref="detailForm"
                 inline label-width="120px">
          <el-form-item label="代理姓名：">
            <span>{{agent_info.agent_name}}</span>
          </el-form-item>
          <el-form-item label="代理编号：">
            <span>{{agent_info.agent_sid}}</span>
          </el-form-item>
          <el-form-item label="代理手机：">
            <span>{{agent_info.phone}}</span>
          </el-form-item>
          <el-form-item label="提交时间：">
            <span>{{agent_withdrawObj.create_time}}</span>
          </el-form-item>
        </el-form>
        <div v-else class="dialog-tips">
          您将{{examineState ===1 ?'同意' : '驳回'}}代理商""的提现申请，请确认提现信息并添加备注后确认出款
        </div>
        <div class="dialog-content">
          <el-row>
            <el-col :span="12">
              <div class="dialog-con-li">
                <span class="dialog-con-li-title">本次提现金额：</span>
                <span class="dialog-con-li-txt">{{agent_withdrawObj.apply_money}}元</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dialog-con-li">
                <span class="dialog-con-li-title">到账方式：</span>
                <span class="dialog-con-li-txt" v-if="agent_withdraw_bind.bind_type == 1">银行卡{{agent_withdraw_bind.account}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dialog-con-li">
                <span class="dialog-con-li-title">持卡人姓名：</span>
                <span class="dialog-con-li-txt">{{agent_withdraw_bind.name}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dialog-con-li">
                <span class="dialog-con-li-title">提现手续费：</span>
                <span class="dialog-con-li-txt">{{agent_withdrawObj.service_charge}}元</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dialog-con-li">
                <span class="dialog-con-li-title">银行预留手机号：</span>
                <span class="dialog-con-li-txt">{{agent_withdraw_bind.phone}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dialog-con-li">
                <span class="dialog-con-li-title">本次提现实到金额：</span>
                <span class="dialog-con-li-txt">{{agent_withdrawObj.real_money}}元</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="examine-state flexbox flex-lr flex-align-center flex-justify-center">
          <div v-if="examineState === 0" class="examine-state-in-progress">
            <el-radio v-model="action" :label="1">审核成功</el-radio>
            <el-radio v-model="action" :label="2">审核失败</el-radio>
          </div>
          <div v-else class="examine-state-success-or-fail flexbox flex-lr">
            <div class="input-dialog-title">备注：</div>
            <el-input class="input-dialog-textarea" style="height: 100px" type="textarea" :rows="2" placeholder="请输入内容"
                      v-model="remark"></el-input>
          </div>
        </div>
      </template>
      <template #footer>
          <el-button type="info" v-if="examineState === 0" @click="next">确定</el-button>
          <el-button type="info" v-if="examineState === 1" @click="submit">确认无误，提交出款</el-button>
          <el-button type="info" v-if="examineState === 2" @click="submit">确认无误，驳回申请</el-button>
      </template>
    </DialogSlot>
  </div>
</template>

<script>
import DialogSlot from '@/components/common/DialogSlot.vue';
import {
  $sg_agent_withdraw,
  $sg_agent_withdraw_detail,
  $sp_agent_withdraw_audit,
} from '@/api/incomingPay';


export default {
  name: 'agentWithdrawal',
  components: {
    DialogSlot,
  },
  data() {
    return {
      form: {
        agent_id: '',
        agent_name: '',
        phone: '',
        apply_state: '',
      },
      time: '',
      tableData: [],
      totalPage: 0,
      page: 1,
      id: '',
      visible: false,
      dialogTitle: '',
      agent_info: {},
      agent_withdrawObj: {},
      agent_withdraw_bind: {},
      examineState: 0,
      action: 1,
      remark: '',
    };
  },
  created() {
    this.search();
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
      this.time = '';
      this.search();
    },
    // 查询
    search() {
      this.currentPage = 1;
      this.agent_withdraw();
    },
    // 请求提现列表
    agent_withdraw() {
      const params = {
        page: this.page,
        page_size: 10,
        start_time: '',
        end_time: '',
        ...this.form,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_agent_withdraw(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 详情
    detail(row) {
      this.examineState = 0;
      this.id = row.id;
      this.dialogTitle = '提现信息详情';
      this.visible = true;
      $sg_agent_withdraw_detail(row.id).then((res) => {
        this.agent_withdrawObj = res.agent_withdraw || {};
        this.agent_info = res.agent_info || {};
        this.agent_withdraw_bind = res.agent_withdraw_bind || {};
      });
    },
    cancel() {
      this.visible = false;
    },
    // 确定
    next() {
      this.examineState = this.action;
    },
    // 提交审核
    submit() {
      if (!this.remark) {
        this.$message.error('备注不能为空');
        return;
      }
      const params = {
        id: this.id,
        action: this.action,
        remark: this.remark,
      };
      $sp_agent_withdraw_audit(params).then(() => {
        this.visible = false;
        this.agent_withdraw();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.agent-withdrawal-index-page{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .list-head-search-view{
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
  }
  .list-main-view{
    padding: 15px 0 0 0;
    box-sizing: border-box;
    .operation-li{
      margin: 0 10px 0 0;
      color: #0E0EFF;
    }
  }
  .dialog-tips{
    font-size: 14px;
    color: #666;
    padding: 10px 20px;
  }
  .dialog-content{
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0 20px;
    box-sizing: border-box;
    .dialog-con-li{
      width: 100%;
      text-align: center;
      margin: 0 0 5px 0;
      font-size: 14px;
      color: #333;
      .dialog-con-li-title{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
      .dialog-con-li-txt{
        display: inline-block;
        width: calc(100% - 150px);
        text-align: left;
      }
    }
  }
  .examine-state{
    border-top: 1px solid #ececec;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
    .examine-state-success-or-fail{
      .input-dialog-title{
        width: 120px;
        text-align: right;
      }
      .input-dialog-textarea ::v-deep{
        .el-textarea__inner{
          width: 500px;
          height: 100px;
        }
      }
    }
  }
}
.components-dialog-page ::v-deep {
  .el-dialog__body {
    padding: 0;
    box-sizing: border-box;
  }
}
</style>
