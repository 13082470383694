<template>
  <div class="merchantList-box">
    <div class="search-box">
      <el-form inline>
        <el-form-item label="商户号">
          <el-input v-model="form.mchid" placeholder="请输入商户号"></el-input>
        </el-form-item>
        <el-form-item label="商户名称">
          <el-input v-model="form.mch_name" placeholder="请输入商户名称"></el-input>
        </el-form-item>
        <el-form-item label="商户电话">
          <el-input v-model="form.contact_phone" placeholder="请输入商户电话"></el-input>
        </el-form-item>
        <el-form-item label="开通时间">
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="mchid" label="商户号" align="center"></el-table-column>
      <el-table-column prop="mch_name" label="商户名称" align="center"></el-table-column>
      <el-table-column prop="contact_phone" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="" label="所属代理商" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.agent">{{scope.row.agent.agent_name}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="收款编码" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.qrcode && scope.row.qrcode.length > 0">
            <span v-for="(item, index) in scope.row.qrcode" :key="index">{{index > 0 ? ' | ' : ''}}{{item.sn}}</span>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="device_box_count" label="绑定设备数量" align="center"></el-table-column>
      <el-table-column prop="" label="开通日期" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.qrcode && scope.row.qrcode.length > 0">
            <span>{{scope.row.qrcode[0].create_time}}</span>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="toEdit(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="getMerchantsList">
    </el-pagination>
  </div>
</template>

<script>
import {
  $sg_merchants,
} from '@/api/incomingPay';

export default {
  data() {
    return {
      form: {
        mchid: '',
        mch_name: '',
        contact_phone: '',
      },
      time: '',
      currentPage: 1,
      totalPage: 0,
      tableData: [],
    };
  },
  created() {
    this.search();
  },
  methods: {
    // 查询
    search() {
      this.currentPage = 1;
      this.getMerchantsList();
    },
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
      this.time = '';
      this.search();
    },
    // 请求商户列表
    getMerchantsList() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        start_time: '',
        end_time: '',
        ...this.form,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_merchants(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 详情
    toEdit(row) {
      this.$router.push({
        name: 'MerchantDetail',
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.merchantList-box {
  width: 100%;
  min-height:100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
