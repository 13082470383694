<template>
  <!-- 设备使用情况 -->
  <div class="equipment-usage-page">
    <el-form :inline="true" :model="form" class="equipment-usage-form">
      <el-form-item>
        <el-date-picker 
          v-model="time" 
          type="daterange" 
          value-format="yyyy-MM-dd" 
          range-separator="至" 
          start-placeholder="开始日期" 
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.type" placeholder="选择型号版本" clearable>
          <el-option v-for="item in versionAndFactoryList" :key="item.type" :label="item.cn + ' ' + item.sn_prefix" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.sn" placeholder="输入设备号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.mch_name" placeholder="请输入商户名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.state" placeholder="选择设备状态" clearable>
          <el-option label="使用中" :value="1"></el-option>
          <el-option label="已停用" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset">重置</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <!-- <el-button @click="addEquipentUsage">添加</el-button> -->
      <el-button @click="changeState(1, 2)">批量停用</el-button>
      <el-button @click="changeState(1, 1)">批量启用</el-button>
    </div>
    <el-table :data="tabelData" ref="tabel" border class="equipment-usage-table" row-key="id" @selection-change="equipmentChange">
      <el-table-column type="selection" reserve-selection width="55" align="center"></el-table-column>
      <el-table-column prop="" label="商户名称" align="center">
        <template slot-scope="scope">
          <span type="text" v-if="scope.row.mch_info">{{scope.row.mch_info.mch_name || '-'}}</span>
          <span type="text" v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="sn" label="设备编号" align="center"></el-table-column>
      <el-table-column prop="batch_no" label="批次号" align="center"></el-table-column>
      <el-table-column prop="type_text" label="机型" align="center"></el-table-column>
      <el-table-column prop="bind_shop_time" label="激活日期" align="center">
        <template slot-scope="scope">
          <span type="text">{{scope.row.bind_shop_time || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == 1">使用中</span>
          <span v-else-if="scope.row.state == 2">已停用</span>
        </template>
        <!-- <template slot-scope="scope">
          <span v-if="scope.row.device_box_online_status == 'NONE'">-</span>
          <span v-else-if="scope.row.device_box_online_status == 'ONLINE'">在线</span>
          <span v-else-if="scope.row.device_box_online_status == 'OFFLINE'">离线</span>
          <span v-else-if="scope.row.device_box_online_status == 'UNACTIVE'">未激活</span>
          <span v-else-if="scope.row.device_box_online_status == 'DISABLE'">禁用</span>
        </template> -->
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="equipmentDetail(scope.row)">详情</el-button>
          <el-button type="text" @click="exportList(scope.row)">导出</el-button>
          <el-button type="text" v-if="scope.row.state == 1" @click="changeState(2, 2, scope.row)">停用</el-button>
          <el-button type="text" v-if="scope.row.state == 2" @click="changeState(2, 1, scope.row)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="page" @current-change="getDevices">
    </el-pagination>
  </div>
</template>

<script>

import {
  $sg_devices_usage_list,
  $sg_devices_specs,
  $sp_device_change_state,
} from '@/api/incomingPay';
import { exportFileSync } from '@base/utils';

export default {
  name: 'EquipmentUsage',
  data() {
    return {
      visible: false,
      form: {
        type: '',
        sn: '',
        mch_name: '',
        state: '',
      },
      time: '',
      totalPage: 0,
      page: 1,
      tabelData: [],
      versionAndFactoryList: [],
      selection: [],
    };
  },
  created() {
    this.devices_specs();
    this.search();
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
      this.time = '';
      this.search();
    },
    // 查询
    search() {
      this.page = 1;
      this.getDevices();
    },
    // 请求设备列表
    getDevices() {
      const params = {
        page: this.page,
        page_size: 10,
        start_time: '',
        end_time: '',
        ...this.form,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_devices_usage_list(params).then((res) => {
        this.tabelData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 请求设备类型列表
    devices_specs() {
      $sg_devices_specs().then((res) => {
        this.versionAndFactoryList = res;
      });
    },
    equipmentChange(val) {
      this.selection = val;
    },
    // 设备导出
    exportList(row) {
      const params = {
        device_box_id: row.id,
      };
      exportFileSync(
        `${process.env.VUE_APP_PAY_DOMAIN}/p/pay/platform/devices_usage_export`,
        params,
        'get',
        '设备使用情况.xls',
      );
    },
    // 停用/启用
    changeState(type, state, row) {
      let params;
      if (type === 1) {
        // 批量
        if (!this.selection || this.selection.length === 0) {
          this.$message.error('请选择设备');
          return;
        }
        const device_box_id = [];
        this.selection.forEach((val) => {
          device_box_id.push(val.id);
        });
        params = {
          device_box_ids: device_box_id,
          state,
        };
      } else if (type === 2) {
        // 单个
        params = {
          device_box_ids: [row.id],
          state,
        };
      }
      let tips;
      if (state === 1) {
        tips = '确定要启用吗?';
      } else if (state === 2) {
        tips = '确定要停用吗?';
      }
      this.$confirm(tips, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
        center: true,
      }).then(() => {
        this.$refs.tabel.clearSelection();
        $sp_device_change_state(params).then(() => {
          if (type === 1) {
            this.page = 1;
          }
          this.getDevices();
        });
      }).catch(() => {});
    },
    // 添加设备
    addEquipentUsage() {
      this.$router.push({ name: 'AddOrEditEquipentUsage' });
    },
    // 设备详情
    equipmentDetail(row) {
      this.$router.push({ 
        name: 'EquipmentUsageDetail',
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.equipment-usage-page {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .equipment-usage-form {
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .equipment-usage-table {
    margin: 20px 0;
  }
}
</style>
