<template>
  <div class="agent-data-index-page">
    <div class="four-show-view flexbox flex-lr flex-align-center">
      <div class="four-show-view-li model1">
        <div class="four-show-view-li-head">今日交易</div>
        <div class="four-show-view-li-content flexbox flex-lr flex-align-center flex-justify-between">
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{agentData.today_trade_money || 0}}</div>
            <div class="four-show-view-li-con-tips">今日交易金额(元)</div>
          </div>
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{+agentData.today_trade_count || 0}}</div>
            <div class="four-show-view-li-con-tips">今日交易笔数</div>
          </div>
        </div>
      </div>
      <div class="four-show-view-li model2">
        <div class="four-show-view-li-head">商户数量</div>
        <div class="four-show-view-li-content flexbox flex-lr flex-align-center flex-justify-between">
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{agentData.today_mch_count || 0}}</div>
            <div class="four-show-view-li-con-tips">今日新增商户</div>
          </div>
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{agentData.mch_count || 0}}</div>
            <div class="four-show-view-li-con-tips">累计商户</div>
          </div>
        </div>
      </div>
      <div class="four-show-view-li model3">
        <div class="four-show-view-li-head">今日提现申请</div>
        <div class="four-show-view-li-content flexbox flex-lr flex-align-center flex-justify-between">
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{agentData.today_withdraw_agent_count || 0}}</div>
            <div class="four-show-view-li-con-tips">提现代理商数量</div>
          </div>
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{agentData.today_agent_withdraw_money || 0}}</div>
            <div class="four-show-view-li-con-tips">提现总金额(元)</div>
          </div>
        </div>
      </div>
      <div class="four-show-view-li model4">
        <div class="four-show-view-li-head">累计数据</div>
        <div class="four-show-view-li-content flexbox flex-lr flex-align-center flex-justify-center">
          <div class="four-show-view-li-con flexbox flex-tb flex-align-center flex-justify-center">
            <div class="four-show-view-li-con-num">{{agentData.trade_money || 0}}</div>
            <div class="four-show-view-li-con-tips">累计交易金额(元)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="five-show-view">
      <div class="five-show-view-title">订单数据</div>
      <div class="five-show-view-content flexbox flex-lr flex-align-center">
        <div class="five-content-li flexbox flex-tb flex-align-center flex-justify-center">
          <div class="li-num">{{agentData.month_trade_money || 0}}</div>
          <div class="li-title">
            本月流水金额
            <el-tooltip effect="dark" content="本月流水金额指当前自然月中商户收款资金的总和" placement="bottom-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="five-content-li flexbox flex-tb flex-align-center flex-justify-center">
          <div class="li-num">{{agentData.month_commission || 0}}</div>
          <div class="li-title">
            本月可抽佣金额
            <el-tooltip effect="dark" content="本月可抽佣金额指当前自然月中所有商户产生的可抽佣金额总和" placement="bottom-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="five-content-li flexbox flex-tb flex-align-center flex-justify-center line-right">
          <div class="li-num">{{agentData.month_device_box_count || 0}}</div>
          <div class="li-title">
            本月激活云喇叭
            <el-tooltip effect="dark" content="本月激活云喇叭指当前自然月中新增的云喇叭激活数量" placement="bottom-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="five-content-li flexbox flex-tb flex-align-center flex-justify-center">
          <div class="li-num">{{agentData.month_valid_merchant_count || 0}}</div>
          <div class="li-title">
            本月新增有效商户
            <el-tooltip effect="dark" content="本月新增有效商户指当前自然月中进件通过且成功绑码的商户数量" placement="bottom-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="five-content-li flexbox flex-tb flex-align-center flex-justify-center">
          <div class="li-num">{{agentData.gt_200_thousand_count || 0}}</div>
          <div class="li-title">
            流水>20万的商户
            <el-tooltip effect="dark" content="流水>20万的商户指当前自然月中商户的收款金额大于20万的商户数量" placement="bottom-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="echarts-about-view flexbox flex-lr flex-align-center">
      <div class="echarts-line">
        <div id="echartsHistogram"></div>
        <div class="echarts-title flexbox flex-lr flex-align-center flex-justify-center">本月商户交易数据</div>
      </div>
      <div class="echart-pie">
        <div id="echartPie"></div>
        <div class="echart-title flexbox flex-lr flex-align-center flex-justify-center">本月交易区间商户数量</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as eCharts from 'echarts';
import {
  $sg_agent_statistics,
  $sg_month_trade_statistics,
  $sg_month_trade_mch_count,
} from '@/api/incomingPay';

export default {
  name: 'agentData',
  data() {
    return {
      agentData: {},
      echartsHistogram: {
        xAxis: [],
        yAxis1: [],
        yAxis2: [],
      },
      summaryList: [],
      summaryValueList: [
        {
          name: '20000以下',
          value: 0,
          label: {
            color: '#5894FF',
          },
          itemStyle: {
            color: '#5894FF',
          },
        },
        {
          name: '20001-50000',
          value: 0,
          label: {
            color: '#72CDD7',
          },
          itemStyle: {
            color: '#72CDD7',
          },
        },
        {
          name: '50001-80000',
          value: 0,
          label: {
            color: '#60B565',
          },
          itemStyle: {
            color: '#60B565',
          },
        },
        {
          name: '80001-100000',
          value: 0,
          label: {
            color: '#FFCF48',
          },
          itemStyle: {
            color: '#FFCF48',
          },
        },
        {
          name: '100001-150000',
          value: 0,
          label: {
            color: '#FF8033',
          },
          itemStyle: {
            color: '#FF8033',
          },
        },
        {
          name: '150001-200000',
          value: 0,
          label: {
            color: '#EE5846',
          },
          itemStyle: {
            color: '#EE5846',
          },
        },
        {
          name: '200000以上',
          value: 0,
          label: {
            color: '#5087EC',
          },
          itemStyle: {
            color: '#5087EC',
          },
        },
      ],
    };
  },
  created() {
  },
  mounted() {
    this.agent_statistics();
    this.month_trade_statistics();
    this.month_trade_mch_count();
  },
  methods: {
    eChartsHistogram() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = eCharts.init(document.getElementById('echartsHistogram'));
      const option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.echartsHistogram.xAxis,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '交易金额',
            type: 'bar',
            data: this.echartsHistogram.yAxis1,
            itemStyle: {
              color: '#6fbec7',
            },
          },
          {
            name: '已绑定云喇叭商户',
            type: 'bar',
            data: this.echartsHistogram.yAxis2,
            itemStyle: {
              color: '#5087ec',
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    eChartsPie() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = eCharts.init(document.getElementById('echartPie'));
      // 指定图表的配置项和数据
      const option = {
        tooltip: {
          trigger: 'item',
          formatter(params) {
            return `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:${params.data.itemStyle.color}"></span>${params.data.name}   ${params.data.value}<br />`;
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['0', '70%'],
            center: ['50%', '50%'],
            data: this.summaryValueList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // 统计-代理商数据-总数
    agent_statistics() {
      $sg_agent_statistics().then((res) => {
        this.agentData = res;
      });
    },
    // 柱状图
    month_trade_statistics() {
      $sg_month_trade_statistics().then((res) => {
        const date_text = [];
        const value1 = [];
        const value2 = [];
        res.forEach((val) => {
          if (val.tag === 'EVERY_DAY_TRANSACTION_AMOUNT') {
            date_text.push(val.date_text);
            value1.push(+val.value);
          } else if (val.tag === 'EVERY_DAY_DEVICE_BOX_COUNT') {
            value2.push(+val.value);
          }
        });
        this.echartsHistogram.xAxis = date_text;
        this.echartsHistogram.yAxis1 = value1;
        this.echartsHistogram.yAxis2 = value2;
        this.eChartsHistogram();
      });
    },
    // 饼状图
    month_trade_mch_count() {
      $sg_month_trade_mch_count().then((res) => {
        this.summaryValueList[0].value = res.lt20000 || 0;
        this.summaryValueList[1].value = res.between20001to50000 || 0;
        this.summaryValueList[2].value = res.between50001to80000 || 0;
        this.summaryValueList[3].value = res.between80001to100000 || 0;
        this.summaryValueList[4].value = res.between100001to150000 || 0;
        this.summaryValueList[5].value = res.between150001to200000 || 0;
        this.summaryValueList[6].value = res.gt200000 || 0;
        this.eChartsPie();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.agent-data-index-page{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .four-show-view{
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .four-show-view-li{
      width: 25%;
      min-width: 300px;
      height: 150px;
      margin: 0 20px 0 0;
      padding: 15px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      .four-show-view-li-head{
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        line-height: 28px;
      }
      .four-show-view-li-content{
        .four-show-view-li-con{
          width: 50%;
          height: 80px;
          margin: 0;
          .four-show-view-li-con-num{
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            line-height: 28px;
            margin-top: 20px;
          }
          .four-show-view-li-con-tips{
            font-size: 18px;
            font-weight: 400;
            color: #fff;
            margin-top: 10px;
          }
        }
      }
    }
    .model1{
      background-color: #39a3d0;
    }
    .model2{
      background-color: #56a87f;
    }
    .model3{
      background-color: #85d77f;
    }
    .model4{
      background-color: #f0955b;
    }
    .four-show-view-li:last-child{
      margin: 0;
    }
  }
  .five-show-view{
    width: 100%;
    height: 200px;
    margin: 20px 0 0 0;
    padding: 15px 0;
    box-sizing: border-box;
    background-color: #f5f5f5;
    border-radius: 8px;
    .five-show-view-title{
      font-size: 18px;
      color: #333;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 20px;
    }
    .five-show-view-title::before{
      content: '';
      display: inline-block;
      width: 4px;
      height: 24px;
      margin: 0 10px 0 0;
      padding: 0;
      box-sizing: border-box;
      background-color: #f88a33;
      vertical-align: middle;
      border-radius: 4px;
    }
    .five-show-view-content{
      .five-content-li{
        width: 20%;
        min-width: 250px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        .li-num{
          font-weight: 700;
          font-size: 24px;
          color: #484c5c;
          line-height: 36px;
          margin: 10px 0 0 0;
        }
        .li-title{
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin: 20px 0 0 0;
          .question{
            font-size: 12px;
            margin: 0 0 0 3px;
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }
  .echarts-about-view{
    width: 100%;
    min-height: 400px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .echarts-line{
      width: calc(100% - 500px);
      min-width: 800px;
      height: 400px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      #echartsHistogram{
        width: 100%;
        height: 400px;
      }
    }
    .echart-pie{
      width: 500px;
      height: 400px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      #echartPie{
        width: 500px;
        height: 400px;
      }
    }
    .echarts-title{
      font-size: 16px;
      color: #444;
      margin: 5px 0 0 0;
    }
  }
  .line-right{
    border-right: 1px solid #999;
  }
}
</style>
