<template>
  <el-dialog ref="dialog" :close-on-click-modal="false" :close-on-press-escape="false"
    v-bind="$attrs" :visible.sync="visibleValue" v-on="$listeners" class="components-dialog-page"
    :class="[{ 'body-line-top': lineTop }, { 'body-line-bottom': lineBottom }]">
    <div class="dialog-txt flexbox flex-lr flex-align-center flex-justify-center" v-if="textBoxShow">
      {{ dialogTxt }}
    </div>
    <slot name="content"></slot>
    <span slot="footer" class="dialog-footer" :class="btnCenter ? 'btn-center' : 'btn-between'">
      <template v-if="footerShow">
        <div><slot name="other"></slot></div>
        <div>
          <el-button type="info" size="small" @click="cancel">取消</el-button>
          <el-button type="primary" size="small" @click="submit">确定</el-button>
        </div>
      </template>
      <slot name="footer" v-else></slot>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    // 中间显示文本
    dialogTxt: {
      type: String,
      default: '',
    },
    // 是否显示弹窗
    visible: {
      type: Boolean,
      default: false,
    },
    // 按钮是否居中
    btnCenter: {
      type: Boolean,
      default: false,
    },
    // 标题下边的横线是否有
    lineTop: {
      type: Boolean,
      default: true,
    },
    // 按钮上边的横线是否有
    lineBottom: {
      type: Boolean,
      default: true,
    },
    // 是否有文字区域
    textBoxShow: {
      type: Boolean,
      default: true,
    },
    // 是否有底部按钮区域
    footerShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    visibleValue: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped lang="scss">
.components-dialog-page ::v-deep {
  .el-dialog {
    border-radius: 6px;
    overflow: hidden;
    background: #fff;
  }
  .el-dialog__header {
    color: #333;
    font-weight: bold;
    padding: 14px 20px 10px 20px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 16px;
  }
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
  }
  .dialog-txt {
    padding: 0 50px;
  }
  .btn-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .btn-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.body-line-top ::v-deep {
  .el-dialog__body {
    border-top: 1px solid #ececec;
  }
}
.body-line-bottom ::v-deep {
  .el-dialog__body {
    border-bottom: 1px solid #ececec;
  }
}
</style>
