<template>
  <div class="equipment-box-manager-page">
    <div class="list-head-search-view">
      <el-form ref="form" :model="form" inline>
        <el-form-item>
          <el-date-picker v-model="time" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.type" placeholder="请选择版本型号" clearable>
            <el-option v-for="item in versionAndFactoryList" :key="item.type" :label="item.cn + ' ' + item.sn_prefix" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input type="text" v-model="form.batch_no" placeholder="输入批次号"></el-input>
        </el-form-item>
        <el-form-item v-if="activeTable === 1">
          <el-input type="text" v-model="form.sn" placeholder="输入设备号"></el-input>
        </el-form-item>
        <el-form-item v-if="activeTable === 1">
          <el-select v-model="form.state" placeholder="选择状态" clearable>
            <el-option label="待入库" :value="1"></el-option>
            <el-option label="已入库" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="list-head-search-button-view flexbox flex-lr flex-align-center flex-justify-center">
        <el-button plain @click="reset">重置</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </div>
    <div class="list-main-view">
      <div class="list-table-change-and-button flexbox flex-lr flex-align-center flex-justify-between">
        <div class="list-table-change flexbox flex-lr">
          <div class="list-table-head" :class="{'active-table': activeTable === 1}" @click="chooseTableHead(1)">设备列表</div>
          <div class="list-table-head" :class="{'active-table': activeTable === 2}" @click="chooseTableHead(2)">批次列表</div>
        </div>
        <div class="list-operation-button flexbox flex-lr flex-align-center">
          <el-button plain @click="addEquipment(1)">新增</el-button>
          <el-button plain @click="addEquipment(2)">批量新增</el-button>
          <!-- <el-button plain v-if="activeTable === 1" @click="delEquipment">批量删除</el-button> -->
          <el-dropdown class="more-operation" v-if="activeTable === 1" @command="exportEquipments">
            <el-button plain>
              导出<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="select">导出已勾选</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div v-show="activeTable === 1" class="list-main-table-equipment">
        <el-table ref="eTable" :data="equipmentTableData" border style="width: 100%" row-key="id" @selection-change="equipmentChange">
          <el-table-column type="selection" reserve-selection width="55" align="center"></el-table-column>
          <el-table-column prop="sn" label="设备编号" align="center"></el-table-column>
          <el-table-column prop="type_text" label="机型" align="center"></el-table-column>
          <el-table-column prop="batch_no" label="批次" align="center"></el-table-column>
          <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
          <el-table-column prop="put_in_storage_time" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 1">待入库</span>
              <span v-if="scope.row.state == 2">已入库</span>
            </template>
          </el-table-column>
          <el-table-column prop="put_in_storage_time" label="入库日期" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.put_in_storage_time || '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="detail(scope.row)">详情</el-button>
              <el-button type="text" v-if="scope.row.state == 1" @click="exportList(scope.row)">导出</el-button>
              <el-button type="text" v-if="scope.row.state == 1" @click="intoWarehouse(scope.row)">入库</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="eTotalPage"
                       :current-page.sync="ePage" @current-change="getDevices">
        </el-pagination>
      </div>
      <div v-show="activeTable === 2" class="list-main-table-batch">
        <el-table ref="bTable" :data="batchTableData" border style="width: 100%">
          <el-table-column prop="type_text" label="机型" align="center"></el-table-column>
          <el-table-column prop="no" label="批次" align="center"></el-table-column>
          <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
          <el-table-column prop="create_time" label="未入库数量" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.total - scope.row.device_box_count}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="device_box_count" label="入库数量" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="batchDetail(scope.row)">详情</el-button>
              <el-button type="text" @click="batchExportList(scope.row)">导出</el-button>
              <el-button type="text" @click="batchAllIntoWarehouse(scope.row)">入库</el-button>
              <el-button type="text" @click="batchIntoWarehouse(scope.row)">部分入库</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="bTotalPage"
                       :current-page.sync="bPage" @current-change="devices_batch_list">
        </el-pagination>
      </div>
    </div>
    <DialogSlot ref="addForm" :visible.sync="visible1" :title="addTitle" :btnCenter="true"
                width="450px" @cancel="visible1 = false" @submit="addSubmit">
      <template #content>
        <el-form ref="addForm" :model="addForm" inline label-width="120px">
          <el-form-item label="机型">
            <el-select v-model="addForm.type" placeholder="请选择版本型号" clearable>
              <el-option v-for="item in versionAndFactoryList" :key="item.type" :label="item.cn + ' ' + item.sn_prefix" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="新增数量" v-if="addForm.handleType === 2">
            <el-input style="width: 215px" v-model.number="addForm.num" placeholder="请输入数量"></el-input>
            <div class="tips">每次新增数量不能大于1000</div>
          </el-form-item>
        </el-form>
      </template>
    </DialogSlot>
    <DialogSlot ref="intoForm" :visible.sync="visible2" title="部分入库" :btn-center="true" width="500px"
                @cancel="intoCancel" @submit="intoSubmit">
      <template #content>
        <el-form ref="updateFile" inline label-width="120px">
          <el-form-item label="上传入库单:">
            <el-upload
              class="upload-demo"
              action="#"
              :http-request="uploadFile"
              accept=".xlsx, .xls"
              :show-file-list="false">
              <div class="flexbox flex-lr flex-align-center">
                <el-input type="text" v-model="file.name" disabled placeholder="上传要入库的设备表格"></el-input>
                <el-button class="upload-demo-btn" size="small" type="primary">上传</el-button>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </template>
    </DialogSlot>
    <DialogSlot :visible.sync="eDetailVisible" :footerShow="false" :btn-center="true" title="设备详情" width="450px">
      <template #content>
        <el-form ref="eDetailForm" :model="eDetailForm" label-width="120px">
          <el-form-item label="设备编号：">
            <span>{{eDetailForm.sn}}</span>
          </el-form-item>
          <el-form-item label="机型：">
            <span>{{eDetailForm.type_text}}</span>
          </el-form-item>
          <el-form-item label="批次：">
            <span>{{eDetailForm.batch_no}}</span>
          </el-form-item>
          <el-form-item label="创建日期：">
            <span>{{eDetailForm.create_time}}</span>
          </el-form-item>
          <el-form-item label="状态：">
            <span v-if="eDetailForm.state == 1">待入库</span>
            <span v-if="eDetailForm.state == 2">已入库</span>
          </el-form-item>
          <el-form-item label="入库时间：">
            <span>{{eDetailForm.put_in_storage_time || '-'}}</span>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button plain @click="eDetailVisible = false">确定</el-button>
      </template>
    </DialogSlot>
  </div>
</template>

<script>
import DialogSlot from '@/components/common/DialogSlot.vue';
import {
  $sg_devices,
  $sg_devicesDetail,
  $sg_devices_specs,
  $sp_addDevices,
  $sp_devices_batch,
  $sg_devices_batch_list,
  $sp_put_in_storage,
  $sp_put_in_storage_single,
  $sp_devices_destroy,
  $sp_put_in_storage_file,
} from '@/api/incomingPay';
import { exportFileSync } from '@base/utils';

export default {
  name: 'equipmentBoxManager',
  components: {
    DialogSlot,
  },
  data() {
    return {
      activeTable: 1,
      versionAndFactoryList: [],
      form: {
        type: '',
        batch_no: '',
        sn: '',
        state: '',
      },
      time: '',
      equipmentTableData: [],
      batchTableData: [],
      selection: [],
      eTotalPage: 0,
      bTotalPage: 0,
      ePage: 1,
      bPage: 1,
      visible1: false,
      visible2: false,
      eDetailVisible: false,
      addTitle: '新增',
      addForm: {
        handleType: '',
        type: '',
        num: '',
      },
      eDetailForm: {},
      batch_id: {}, // 批次id
      file: {},
    };
  },
  created() {
    this.devices_specs();
    this.search();
  },
  methods: {
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
      this.time = '';
      this.search();
    },
    // 查询
    search() {
      if (this.activeTable === 1) {
        this.ePage = 1;
        this.getDevices();
      } else if (this.activeTable === 2) {
        this.bPage = 1;
        this.devices_batch_list();
      }
    },
    // 请求设备列表
    getDevices() {
      const params = {
        page: this.ePage,
        page_size: 10,
        start_time: '',
        end_time: '',
        type: this.form.type,
        batch_no: this.form.batch_no,
        sn: this.form.sn,
        state: this.form.state,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_devices(params).then((res) => {
        this.equipmentTableData = res.list;
        this.eTotalPage = res.total_page;
      });
    },
    // 请求设备类型列表
    devices_specs() {
      $sg_devices_specs().then((res) => {
        this.versionAndFactoryList = res;
      });
    },
    // 选择设备列表/批次列表
    chooseTableHead(val) {
      if (this.activeTable === val) return;
      this.activeTable = val;
      this.reset();
    },
    // 打开设备新增弹窗
    addEquipment(type) {
      this.addForm.handleType = type;
      this.addForm.type = '';
      this.addForm.num = '';
      if (type === 1) {
        this.addTitle = '新增';
      } else {
        this.addTitle = '批量新增';
      }
      this.visible1 = true;
    },
    // 确认新增设备
    addSubmit() {
      const handleType = this.addForm.handleType;
      if (!this.addForm.type) {
        this.$message.error('请选择机型');
        return;
      }
      if (handleType === 1) {
        // 新增
        const params = {
          type: this.addForm.type,
        };
        $sp_addDevices(params).then(() => {
          this.visible1 = false;
          this.search();
        });
      } else {
        if (!this.addForm.num) {
          this.$message.error('新增数量必须大于0');
          return;
        }
        if (+this.addForm.num > 1000) {
          this.$message.error('每次新增数量不能大于1000');
          return;
        }
        // 批量新增
        const params = {
          type: this.addForm.type,
          num: this.addForm.num,
        };
        $sp_devices_batch(params).then(() => {
          this.visible1 = false;
          this.search();
        });
      }
    },
    // 设备批量选择
    equipmentChange(val) {
      this.selection = val;
    },
    // 批量删除设备
    delEquipment() {
      if (!this.selection || this.selection.length === 0) {
        this.$message.error('请选择设备');
        return;
      }
      this.$confirm('确定要删除所选设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
        center: true,
      }).then(() => {
        const ids = [];
        this.selection.forEach((val) => {
          ids.push(val.id);
        });
        const params = {
          ids,
        };
        $sp_devices_destroy(params).then(() => {
          this.selection = [];
          this.$refs.eTable.clearSelection();
          this.search();
        });
      }).catch(() => {});
    },
    // 批量导出设备
    exportEquipments(command) {
      let params;
      if (command === 'select') {
        // 导出已经选择
        if (!this.selection || this.selection.length === 0) {
          this.$message.error('请选择设备');
          return;
        }
        const ids = [];
        this.selection.forEach((val) => {
          ids.push(val.id);
        });
        params = {
          ids,
        };
      }
      exportFileSync(
        `${process.env.VUE_APP_PAY_DOMAIN}/p/pay/platform/devices_export`,
        params,
        'get',
        '设备列表.xls',
      ).then(() => {
        this.selection = [];
        this.eTable.clearSelection();
      });
    },
    // 设备详情
    detail(row) {
      this.eDetailVisible = true;
      $sg_devicesDetail(row.id).then((res) => {
        this.eDetailForm = res;
      });
    },
    // 设备导出
    exportList(row) {
      const ids = [];
      ids.push(row.id);
      const params = {
        ids,
      };
      exportFileSync(
        `${process.env.VUE_APP_PAY_DOMAIN}/p/pay/platform/devices_export`,
        params,
        'get',
        '设备列表.xls',
      );
    },
    // 设备入库
    intoWarehouse(row) {
      this.$confirm('确定要入库?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
        center: true,
      }).then(() => {
        const params = {
          id: row.id,
        };
        $sp_put_in_storage_single(params).then(() => {
          this.getDevices();
        });
      }).catch(() => {});
    },
    // 请求批次列表
    devices_batch_list() {
      const params = {
        page: this.bPage,
        page_size: 10,
        start_time: '',
        end_time: '',
        type: this.form.type,
        no: this.form.batch_no,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_devices_batch_list(params).then((res) => {
        this.batchTableData = res.list;
        this.bTotalPage = res.total_page;
      });
    },
    // 批次详情
    batchDetail(row) {
      this.$router.push({
        name: 'EquipmentBatchDetail',
        query: {
          no: row.no,
        },
      });
    },
    // 批次导出
    batchExportList(row) {
      const params = {
        batch_id: row.id,
      };
      exportFileSync(
        `${process.env.VUE_APP_PAY_DOMAIN}/p/pay/platform/devices_export`,
        params,
        'get',
        '批次列表.xls',
      );
    },
    // 批次入库
    batchAllIntoWarehouse(row) {
      this.$confirm(`是否要全部入库批次为${row.no}的所有设备?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
      }).then(() => {
        const params = {
          batch_id: row.id,
        };
        $sp_put_in_storage(params).then(() => {
          this.devices_batch_list();
        });
      }).catch(() => {});
    },
    // 批次部分入库
    batchIntoWarehouse(row) {
      this.batch_id = row.id;
      this.file = '';
      this.visible2 = true;
    },
    intoCancel() {
      this.visible2 = false;
    },
    intoSubmit() {
      if (!this.file) {
        this.$message.error('请上传入库单');
        return;
      }
      const formData = new FormData();
      formData.append('batch_id', this.batch_id);
      formData.append('file', this.file);
      $sp_put_in_storage_file(formData).then(() => {
        this.visible2 = false;
        this.devices_batch_list();
      });
    },
    // 文件上传
    uploadFile(file) {
      this.file = file.file;
    },
  },
};
</script>

<style scoped lang="scss">
.equipment-box-manager-page{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .list-head-search-view{
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
  }
  .list-main-view{
    padding: 15px 0 0 0;
    box-sizing: border-box;
    .list-table-change-and-button{
      padding: 0 1px;
      box-sizing: border-box;
      .list-table-change{
        .list-table-head{
          width: 175px;
          height: 40px;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          background-color: #eceef2;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          color: #444;
          position: relative;
          bottom: -1px;
          cursor: pointer;
          user-select: none;
        }
        .active-table{
          background-color: #fff;
          border: 1px solid #ef3f46;
          color: #ef3f46;
        }
      }
      .list-operation-button{
        .more-operation{
          margin: 0 0 0 10px;
        }
      }
    }
  }
  .upload-demo-btn{
    margin: 0 0 0 10px;
  }
  .tips {
    color: #999;
  }
}
</style>
