<template>
  <div class="equipment-batch-detail-page">
    <div class="list-head-search-view">
      <el-form ref="form" :model="form" inline label-width="120px">
        <el-form-item label="筛选">
          <el-select v-model="form.state" placeholder="状态" clearable @change="getList">
            <el-option label="待入库" :value="1"></el-option>
            <el-option label="已入库" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="list-main-view">
      <el-table ref="eTable" :data="tableData" border style="width: 100%">
        <el-table-column prop="sn" label="设备编号" align="center"></el-table-column>
        <el-table-column prop="type_text" label="机型" align="center"></el-table-column>
        <el-table-column prop="batch_no" label="批次" align="center"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待入库</span>
            <span v-if="scope.row.state == 2">已入库</span>
          </template>
        </el-table-column>
          <el-table-column prop="put_in_storage_time" label="入库日期" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.put_in_storage_time || '-'}}</span>
            </template>
          </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row)">详情</el-button>
            <el-button type="text" @click="batchExportList(scope.row)">导出</el-button>
            <el-button type="text" v-if="scope.row.state == 1" @click="batchAllIntoWarehouse(scope.row)">入库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt10" background :page-count="totalPage"
                     :current-page.sync="page" @current-change="getDevices">
      </el-pagination>
    </div>
    <DialogSlot :visible.sync="visible" :footerShow="false" :btn-center="true" title="设备详情" width="450px">
      <template #content>
        <el-form ref="detailForm" :model="detailForm" label-width="120px">
          <el-form-item label="设备编号：">
            <span>{{detailForm.sn}}</span>
          </el-form-item>
          <el-form-item label="机型：">
            <span>{{detailForm.type_text}}</span>
          </el-form-item>
          <el-form-item label="批次：">
            <span>{{detailForm.batch_no}}</span>
          </el-form-item>
          <el-form-item label="创建日期：">
            <span>{{detailForm.create_time}}</span>
          </el-form-item>
          <el-form-item label="状态：">
            <span v-if="detailForm.state == 1">待入库</span>
            <span v-if="detailForm.state == 2">已入库</span>
          </el-form-item>
          <el-form-item label="入库时间：">
            <span>{{detailForm.put_in_storage_time || '-'}}</span>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button plain @click="visible = false">确定</el-button>
      </template>
    </DialogSlot>

  </div>
</template>

<script>
import DialogSlot from '@/components/common/DialogSlot.vue';
import {
  $sg_devices,
  $sg_devicesDetail,
  $sp_put_in_storage_single,
} from '@/api/incomingPay';
import { exportFileSync } from '@base/utils';

export default {
  name: 'equipmentBatchDetail',
  components: {
    DialogSlot,
  },
  data() {
    return {
      no: '',
      form: {
        state: '',
      },
      tableData: [],
      totalPage: 0,
      page: 1,
      visible: false,
      detailForm: {},
    };
  },
  created() {
    if (this.$route.query.no) {
      this.no = this.$route.query.no;
      this.getList();
    }
  },
  methods: {
    getList() {
      this.page = 1;
      this.getDevices();
    },
    // 请求设备列表
    getDevices() {
      const params = {
        page: this.page,
        page_size: 10,
        batch_no: this.no,
        state: this.form.state,
      };
      $sg_devices(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 详情
    detail(row) {
      this.visible = true;
      $sg_devicesDetail(row.id).then((res) => {
        this.detailForm = res;
      });
    },
    // 批次导出
    batchExportList(row) {
      const params = {
        ids: [row.id],
      };
      exportFileSync(
        `${process.env.VUE_APP_PAY_DOMAIN}/p/pay/platform/devices_export`,
        params,
        'get',
        '设备列表.xls',
      );
    },
    // 入库
    batchAllIntoWarehouse(row) {
      this.$confirm('确定要入库?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
      }).then(() => {
        const params = {
          id: row.id,
        };
        $sp_put_in_storage_single(params).then(() => {
          this.getDevices();
        });
      }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.equipment-batch-detail-page{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .list-head-search-view{
    border-bottom: 1px solid #f5f5f5;
  }
  .list-main-view{
    padding: 15px 0 0 0;
    box-sizing: border-box;
  }
}
</style>
