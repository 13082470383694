/* eslint-disable max-len */
<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      :width="svgSize"
      :height="svgSize"
      style="
        border-color: rgba(0, 0, 0, 0);
        border-width: bpx;
        border-style: undefined;
      "
      filter="none"
    >
      <path
        d="M25.2 11.8c0-5-4-9.2-9.2-9.2s-9.2 4-9.2 9.2c-3.8 0-6.8 3-6.8 6.8s3 6.8 6.8 6.8h18.2c3.8 0 6.8-3 6.8-6.8s-2.8-6.8-6.6-6.8zM25.2 23.4h-18.4c-2.6 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8h2v-2c0-4 3.2-7.2 7.2-7.2s7.2 3.2 7.2 7.2v2h2c2.6 0 4.8 2.2 4.8 4.8s-2.2 4.8-4.8 4.8z"
        fill="rgba(36, 255, 0, 1)"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    svgSize: {
      default: '18',
    },
  },
};
</script>

<style>
</style>
