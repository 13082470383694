<template>
  <div class="payCodeTable-content">
    <el-page-header @back="$router.back()" content="批次列表"> </el-page-header>
    <div class="date-box">
      <el-date-picker v-model="time" value-format="yyyy-MM" type="month" placeholder="选择月"></el-date-picker>
    </div>
    <div class="form-box">
      <el-form inline>
        <el-form-item label="收款码状态">
        <el-select v-model="form.qrcode_state" placeholder="请选择" clearable>
          <el-option label="已使用" :value="1"> </el-option>
          <el-option label="未使用" :value="2"> </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="商户名称">
          <el-input placeholder="商户名称/电话" v-model="form.keywords"></el-input>
        </el-form-item>
        <el-form-item label="商户号">
          <el-input placeholder="商户号" v-model="form.mchid"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button @click="reset">重置</el-button>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <i
        :class="isTableShow ? 'el-icon-s-unfold' : 'el-icon-menu'"
        @click="isTableShow = !isTableShow"
      ></i>
    </div>
    <template v-if="isTableShow">
      <el-table ref="secTable" :data="tableData" border>
        <el-table-column prop="sn" label="收款码编号" align="center"></el-table-column>
        <el-table-column label="商户名称" prop="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.merchant">{{scope.row.merchant.mch_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="联系电话" prop="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.merchant">{{scope.row.merchant.contact_phone}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="商户号" prop="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.merchant">{{scope.row.merchant.mchid || '-'}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定设备数量" prop="box_count" align="center"></el-table-column>
        <el-table-column label="批次号" prop="batch_no" align="center"></el-table-column>
        <el-table-column label="开通日期" prop="create_time" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 2">已失效</span>
            <span v-if="scope.row.state == 1 && scope.row.is_used == 1">已使用</span>
            <span v-if="scope.row.state == 1 && scope.row.is_used == 0">未使用</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="qrcodeDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <div class="code-img-box">
        <div
          class="code-img-item"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="code-info">
            <span>松鼠惠支付</span>
            <img :src="getQrcodeSrc(item.qrcode_url)" alt="" />
            <span>{{item.sn}}</span>
          </div>
          <span v-if="item.merchant">商户号:{{item.merchant.mchid || '-'}}</span>
          <span v-else>商户号: -</span>
          <span v-if="item.merchant">{{item.merchant.mch_name || '-'}}</span>
          <div class="code-type" v-if="item.is_used == 1">已使用</div>
          <div class="code-type" v-if="item.is_used == 0">未使用</div>
        </div>
      </div>
    </template>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="qrcode_list">
    </el-pagination>
    <!-- 二维码详情 -->
    <CodeInfo ref="codeInfo" />
  </div>
</template>

<script>
import {
  $sg_qrcode_list,
} from '@/api/incomingPay';
import { getQrcodeSrc } from '@/global/globalMethods';
import CodeInfo from './components/codeInfo.vue';

export default {
  components: {
    CodeInfo,
  },
  data() {
    return {
      getQrcodeSrc,
      isTableShow: true,
      form: {
        batch_no: '',
        mchid: '',
        sn: '',
        qrcode_state: '',
        keywords: '',
      },
      time: '',
      totalPage: 0,
      currentPage: 1,
      tableData: [],
      dialogShow: false,
    };
  },
  created() {
    this.form.batch_no = this.$route.query.no;
    this.qrcode_list();
  },
  methods: {
    // 查询
    search() {
      this.currentPage = 1;
      this.qrcode_list();
    },
    // 重置
    reset() {
      Object.keys(this.form).forEach((key) => {
        if (key !== 'batch_no') {
          this.form[key] = '';
        }
      });
      this.time = '';
      this.search();
    },
    // 收款码列表
    qrcode_list() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        start_time: '',
        end_time: '',
        ...this.form,
      };
      if (this.time) {
        params.start_time = `${this.time}-01`;
        const time = this.time.split('-');
        // 获取当月最后一天日期
        const lastDay = new Date(time[0], time[1], 0).getDate();
        params.end_time = `${this.time}-${lastDay}`;
      }
      $sg_qrcode_list(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 二维码详情
    qrcodeDetail(row) {
      this.$refs.codeInfo.open(row.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.payCodeTable-content {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .date-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    &:nth-child(2) {
      border-bottom: 1px solid #bbbbbb;
    }
    > i {
      font-size: 20px;
      color: #969ca7;
      cursor: pointer;
    }
  }
  .code-img-box {
    display: flex;
    flex-wrap: wrap;
    .code-img-item {
      box-sizing: border-box;
      width: 157px;
      height: 271px;
      background-color: #f4f5f7;
      border-radius: 4px;
      border: 1px solid #bbbbbb;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;
      margin-top: 20px;
      cursor: pointer;
      .code-info {
        width: 147px;
        height: 189px;
        background-color: #fff;
        border-radius: 4px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ef3f46;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 5px 0 11px;
        img {
          width: 110px;
          height: 110px;
        }
        span {
          &:nth-child(1) {
            font-size: 18px;
            font-weight: bolder;
          }
        }
      }
      > span {
        font-size: 12px;
        color: #101010;
      }
      .code-type {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 4px;
        border-top: 1px solid #bbbbbb;
        background-color: #eceef2;
      }
    }
  }
}
</style>
